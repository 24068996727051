import React, { useRef, useState, useEffect } from "react";
import { Box,  IconButton, Tabs, Tab, TextField, Checkbox, Select,  MenuItem, FormControlLabel, Typography, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import {
  Form,
  Button,
  Card,
  Container,
  Accordion,
  ListGroup,
  Badge,
  Dropdown,
  DropdownButton,
  Row,
  Col,
  Image,
  Modal,
  Breadcrumb,
} from "react-bootstrap";
import { useAuth } from "../contexts/AuthContext";
import { auth, fires } from "../firebase";
import { useParams } from "react-router-dom";
import { isMobile } from "react-device-detect";
import firebase from "firebase";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import "firebase/firestore";
import { CSVLink } from "react-csv";
import SuperAdminAlert from "./SuperAdminAlert";

export default function EventTicketing() {
  const { currentUser, logout } = useAuth();
  const [posts, setPosts] = useState([]);
  const [allSubmission, setAllSubmissions] = useState([]);
  

  const [userLang, setUserLang] = useState("zh");

  const [eventData, setEventData] = useState({
    title: "",
    description: "",
    ticketMessage: "",
    ticketTitle: "",
    price: 0,
    orgId: "",
    prices: {}
  });

  const [eventPrices, setEventPrices] = useState({
    "default-price":
    {priceType: "FREE",
    price: 0,
    priceOriginal: 0,
    quan: 1,
    priceId: "",
    productId: "",
    priceCurrency: "CAD",
    priceEMTEmail: "",}
  })

  const [eventOptions, setEventOptions] = useState({
    comment: false,
    draw: false,
    max: 500,
  });

  const [eventSelectedMember, setEventSelectedMember] = useState({
    name: "",
    email: "",
    forms: [],
    fromUA: "",
    lang: "",
    quantity: 1,
    tickets: [],
    formSubmissions: [],
  });

  const [eventPIN, setEventPIN] = useState("")
  const [currentEventRequest, setCurrentEventRequest] = useState({
    requestId: '',
    eventId: ''
  })

  const [eventDisplayMembers, setEventDisplayMembers] = useState([]);
  const [eventMembers, setEventMembers] = useState([]);
  const [eventTicketMembers, setEventTicketMembers] = useState([]);

  const [eventInvitees, setEventInvitees] = useState([]);
  const [eventInviteeEmails, setEventInviteeEmails] = useState([]);

  const [eventForm, setEventForm] = useState([]);
  const [allTicketsData, setAllTicketsData] = useState([]);
  const [ticketItemCount,setTicketItemCount] = useState({});
  const [allInvoiceData, setAllInvoiceData] = useState([]);
  const [ticketsWithPayment, setTicketsWithPayment] = useState([]);
  const [ticketsWithoutPayment, setTicketsWithoutPayment] = useState([]);

  const [showInvitees, setShowInvitees] = useState(false);
  const [showAddMembers, setShowAddMembers] = useState(false);
  const [showMemberDetails, setShowMemberDetails] = useState(false);

  const eventInviteeEmailRef = useRef();
  const eventInviteeFirstNameRef = useRef();
  const eventInviteeLastNameRef = useRef();

  const eventMemberEmailRef = useRef();
  const eventMemberFirstNameRef = useRef();
  const eventMemberLastNameRef = useRef();
  const eventMemberInviteeEmailRef = useRef();
  const eventMemberNumberRef = useRef();

  const fullScreenHandle = useFullScreenHandle();
  const [enteredFullScreen, setEnteredFullScreen] = useState(false);

  const maxW = isMobile ? "100%" : "100%";
  const [displayMode, setDisplayMode] = useState(0);

  var uid = currentUser.uid;

  const [superAdminInEffect, setSuperAdminInEffect] = useState(false);

  const [inviteeDisplayType, setInviteeDisplayType] = useState(0);

  const [orgData, setOrgData] = useState({});

  let { id } = useParams();

  const [csvData, setCSVData] = useState([
    ["example", "example", "email"],
    ["a", "a", "a@gmail.com"],
    ["b", "b", "b@gmail.com"],
    ["c", "c", "c@gmail.com"],
  ]);

  const [showTicket, setShowTicket] = useState(false);

  const routeToLogout = () => {
    logout();
    let path = "/";
    window.location.href = path;
  };

  const routeToBack = () => {
    let path = "/";
    window.location.href = path;
  };


  const columns = [
    { field: "id", flex: 1, headerName: "Ticket ID" },
    { field: "userName", headerName: "Name", flex: 0.5, cellClassName: "name-column--cell",},
    { field: "eventTitle", headerName: "Ticket Name", flex: 1.5,},
    { field: "ticketName", headerName: "Ticket Type", flex: 1,},
    { field: "price", headerName: "Price", flex: 1,},
    {field: "date",headerName: "Date",
      flex: 0.8,
    },
    {field: "tags",headerName: "Tag",
    flex: 0.5,}
  ];

  const paymentColumns = [
    { field: "id", flex: 0.2, headerName: "Order" },
    { field: "registerName", headerName: "Name", flex: 0.5, cellClassName: "name-column--cell",},
    { field: "registerEmail", headerName: "Email", flex: 0.7,},
    { field: "ticketName", headerName: "Ticket Name", flex: 0.7,},
    { field: "quantity", headerName: "Qty", flex: 0.3,},
    { field: "amount_total", headerName: "Price (CAD)", flex: 0.5,},
    { field: "amount", headerName: "Total Paid (CAD)", flex: 0.5,},
    { field: "amount_discount", headerName: "Discount (CAD)", flex: 0.5,},
    { field: "date", headerName: "Date", flex: 0.7,},
    { field: "coupon", headerName: "Coupon", flex: 0.5,},
  ]

  const invoiceColumns = [
    { field: "id", flex: 0.5, headerName: "Invoice ID" },
    { field: "clientName", headerName: "Name", flex: 0.5, cellClassName: "name-column--cell",},
    { field: "email", headerName: "Email", flex: 0.5,},
    { field: "invoiceDate", headerName: "Date", flex: 0.5,},
    { field: "price", headerName: "Price", flex: 0.5,},
    { field: "tax", headerName: "Tax Return", flex: 0.5,},
    { field: "emailId", headerName: "Email Sent", flex: 0.5,},
  ]

  const requestColumns = [
    { field: "id", flex: 0.5, headerName: "Request ID" },
    { field: "firstName", headerName: "First Name", flex: 0.5,},
    { field: "lastName", headerName: "Last Name", flex: 0.5,},
    { field: "email", headerName: "Email", flex: 0.5,},
    { field: "status", headerName: "status", flex: 0.5,},
    {
      field: "action",
      headerName: "Actions",
      sortable: false,
      flex: 1.0,
      renderCell: (params) => {
        const approveClick = (e) => {
          e.stopPropagation();
          return alert('selected');
        };
        const rejectClick = (e) => {
          e.stopPropagation();
          return alert('selected');
        };
        return <div>
          <Button 
            variant="outline-success" 
            onClick={approveClick}>Approve</Button>
          <Button 
            variant="outline-danger"
            style={{marginLeft: '5px'}}
            onClick={rejectClick}>Reject</Button>
          </div>;
      }
    },
  ]


  const webTicketComponent = () => (!isMobile && allTicketsData.length != 0 && tabValue == 'tickets' &&
    <Box
        width="100%"
        marginTop="25px"
      >
        <Card.Title>Tickets: {allTicketsData.length} </Card.Title>
        <DataGrid 
        checkboxSelection 
        sx={{ overflowX: 'scroll' }}
        rows={allTicketsData} 
        columns={columns} />
      </Box>
  )

  const webInvoiceComponent = () => (!isMobile && allInvoiceData.length != 0 && tabValue == 'invoices' &&
    <Box
        width="100%"
        marginTop="25px"
      >
        <Card.Title>Invoices: {allInvoiceData.length} </Card.Title>
        <DataGrid 
        checkboxSelection
        
        sx={{ overflowX: 'scroll' }}
        rows={allInvoiceData} 
        columns={invoiceColumns} />
      </Box>
  )

  const webRequestComponent = () => (!isMobile && eventRequests.length != 0 && tabValue == 'requests' &&
    <Box
        width="100%"
        marginTop="25px"
      >
        <Card.Title>Requests: {eventRequests.length} </Card.Title>
        <DataGrid 
        checkboxSelection
        sx={{ overflowX: 'scroll' }}
        rows={eventRequests} 
        columns={requestColumns} />
      </Box>
  )

  const webPaymentComponent = () => (!isMobile && paymentData.length != 0 && tabValue == 'payments' &&
    <Box
        width="100%"
        marginTop="25px"
      >
        <Card.Title>Payments: {paymentData.length} </Card.Title>
        <DataGrid 
        checkboxSelection 
        editMode="row"
        sx={{ overflowX: 'scroll', marginTop: '15px' }}
        
        
        rows={paymentData} 
        columns={paymentColumns} />
        <Button
        variant="outline-dark"
        style={{marginTop: '15px', marginRight: '10px'}} 
        hidden={eventPaymentNextPage != ""} onClick={()=>{
          setEventPaymentNextPage("")
          loadEventPaymentData(orgData.id)
        }}>Reload</Button>
        <Button
        variant="outline-dark"
        style={{marginTop: '15px'}} 
        hidden={eventPaymentNextPage == ""} onClick={()=>{
          loadEventPaymentData(orgData.id)
        }}>Next</Button>
      </Box>
  )

  const webMemberComponent = () => (!isMobile && tabValue == 'members' && 
    <ListGroup
                      style={{ marginTop: "25px" }}
                      hidden={displayMode == 1 || isMobile}
                    >
                      <ListGroup.Item
                        className=""
                        style={{ backgroundColor: "#EEE" }}
                      >
                        <Row md={6}>
                          <Col>
                            <Card.Text>
                              {"Name"}
                            </Card.Text>
                          </Col>
                          <Col>
                            <Card.Text>
                              {"Email"}
                            </Card.Text>
                          </Col>
                          <Col>
                            <Card.Text>
                              {"Qty"}
                            </Card.Text>
                          </Col>
                          <Col>
                            <Card.Text>
                              {"Forms"}
                            </Card.Text>
                          </Col>
                          <Col>
                            <Card.Text>
                              {"Tickets"}
                            </Card.Text>
                          </Col>
                          <Col>
                            <Card.Text>
                              {"Actions"}
                            </Card.Text>
                          </Col>
                        </Row>
                      </ListGroup.Item>
                      {eventDisplayMembers.map((memberItm) => {
                        var langId = "zh";
                        var invitedEmail = "";
                        var quan = 1;
                        if (memberItm.lang != null) {
                          langId = memberItm.lang;
                        }
                        if (memberItm.invitedBy != null) {
                          invitedEmail = memberItm.invitedBy;
                        }
                        if (memberItm.quantity != null) {
                          quan = memberItm.quantity;
                        }
                        return (
                          <ListGroup.Item>
                            <Row md={6}>
                              <Col>
                                <Card.Link
                                  style={{ color: "black", fontWeight: "bold" }}
                                  onClick={() => {
                                    setEventSelectedMember(memberItm);
                                    var fromUAString = "";
                                    var eventMemberForms = [];
                                    if (memberItm.forms != null) {
                                      eventMemberForms = memberItm.forms;
                                    }
                                    if (memberItm.fromUA != null) {
                                      fromUAString = memberItm.fromUA;
                                    }
                                    const restSubmissions =
                                      allSubmission.filter((submissionItm) => {
                                        if (submissionItm.email != null) {
                                          return (
                                            memberItm.email ==
                                            submissionItm.email
                                          );
                                        } else {
                                          return false;
                                        }
                                      });
                                    setEventSelectedMember({
                                      ...memberItm,
                                      name: memberItm.name,
                                      email: memberItm.email,
                                      forms: eventMemberForms,
                                      fromUA: fromUAString,
                                      quantity: quan,
                                      formSubmissions: restSubmissions,
                                    });
                                    setShowMemberDetails(true);
                                  }}
                                >
                                  {memberItm.name}
                                </Card.Link>
                              </Col>
                              <Col>
                                <Card.Text>{memberItm.email}</Card.Text>
                              </Col>
                              <Col>
                                <Card.Text>{quan}</Card.Text>
                              </Col>
                              <Col>
                              <Card.Link
                                  style={{ color: "black", fontWeight: "bold" }}
                                  onClick={()=>{
                                    setEventSelectedMember(memberItm);
                                    var fromUAString = "";
                                    var eventMemberForms = [];
                                    if (memberItm.forms != null) {
                                      eventMemberForms = memberItm.forms;
                                    }
                                    if (memberItm.fromUA != null) {
                                      fromUAString = memberItm.fromUA;
                                    }
                                    const restSubmissions =
                                      allSubmission.filter((submissionItm) => {
                                        if (submissionItm.email != null) {
                                          return (
                                            memberItm.email ==
                                            submissionItm.email
                                          );
                                        } else {
                                          return false;
                                        }
                                      });
                                      setEventSelectedMember({
                                        ...memberItm,
                                        name: memberItm.name,
                                        email: memberItm.email,
                                        forms: eventMemberForms,
                                        fromUA: fromUAString,
                                        quantity: quan,
                                        formSubmissions: restSubmissions,
                                      });
                                      setShowMemberDetails(true);
                                  }}
                                  >
                                    查看问卷
                                  </Card.Link>
                              </Col>
                              <Col>
                                <Card.Link
                                  style={{
                                    color: "black",
                                    fontWeight: "bold",
                                  }}
                                  href={
                                    "https://tickets.evtgo.com/ticket-id/" +
                                    memberItm.ticketId
                                  }
                                >
                                  {memberItm.ticketId}
                                </Card.Link>
                              </Col>
                              <Col>
                                <div>
                                  <Card.Link style={{color: 'black', fontWeight: 'bold'}} onClick={()=>{
                                    //create tag for tickets
                                    const tagString = window.prompt("Enter the string for tags, seperated by ',' ")
                                    let tagList = []
                                    if (tagString.includes(',')){
                                      tagList = tagString.split(",")
                                    }
                                    else if (tagString != ""){
                                      tagList = [tagString]
                                    }
                                    addTagIntoUser(
                                      memberItm.email,
                                      memberItm.tickets,
                                      tagList)
                                    
                                  }}>Tag</Card.Link>
                                  <Card.Link style={{color: 'black', fontWeight: 'bold'}}>Check-In</Card.Link>
                                </div>
                              </Col>
                            </Row>
                          </ListGroup.Item>
                        );
                      })}
                    </ListGroup>)

  const [tabValue, setTabValue] = useState("payments")
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const tabSectionComponent = () => ( !isMobile &&
    <Tabs 
        value={tabValue} 
        onChange={handleTabChange}
        aria-label="ticket"
        style={{marginTop: 25, marginBottom: 25}}
        TabIndicatorProps={{
          style: {
            display: 'none'
          }
        }}>
      <Tab
        value={"payments"}
        label="Payments"
        style={{
          fontSize: 17, 
          fontWeight: 'bold',
          borderRadius: 20,
          height: 40,
          backgroundColor: tabValue == 'payments' ? '#F6AC19' : 'transparent',
          color: tabValue == 'payments' ? 'white' : 'black'}}
      />
      <Tab
        value={"members"}
        label="Members"
        style={{
          fontSize: 17, 
          fontWeight: 'bold',
          borderRadius: 20,
          height: 40,
          backgroundColor: tabValue == 'members' ? '#F6AC19' : 'transparent',
          color: tabValue == 'members' ? 'white' : 'black'}}
      />
      <Tab
        value={"tickets"}
        label="Tickets"
        style={{
          fontSize: 17, 
          fontWeight: 'bold', 
          borderRadius: 20,
          height: 40,
          backgroundColor: tabValue == 'tickets' ? '#F6AC19' : 'transparent',
          color: tabValue == 'tickets' ? 'white' : 'black'}}
      />
      <Tab
        value={"invoices"}
        label="Invoices"
        style={{
          fontSize: 17, 
          fontWeight: 'bold', 
          borderRadius: 20,
          height: 40,
          backgroundColor: tabValue == 'invoices' ? '#F6AC19' : 'transparent',
          color: tabValue == 'invoices' ? 'white' : 'black'}}
      />
      <Tab
        value={"requests"}
        label="Requests"
        style={{
          fontSize: 17, 
          fontWeight: 'bold', 
          borderRadius: 20,
          height: 40,
          backgroundColor: tabValue == 'requests' ? '#F6AC19' : 'transparent',
          color: tabValue == 'requests' ? 'white' : 'black'}}
      />
    </Tabs>
  )

  function addTagIntoUser(memberEmail,tickets,tags){
    fires
    .collection('events')
    .doc(id)
    .collection('members')
    .doc(memberEmail)
    .update({tags: tags})
    .then((snap) => {
      tickets.forEach((ticketId)=>{
        fires
          .collection('events')
          .doc(id)
          .collection('tickets')
          .doc(ticketId)
          .update({tags: tags})
      })
      window.alert('Tags has been added successfully')
    })
  }
  

  const isToday = (someDate) => {
    const today = new Date();
    return (
      someDate.getDate() == today.getDate() &&
      someDate.getMonth() == today.getMonth() &&
      someDate.getFullYear() == today.getFullYear()
    );
  };

  const [eventRequests, setEventRequests] = useState([])
  async function loadEventRequest(){
    fires
      .collection("events")
      .doc(id)
      .collection("requests")
      .orderBy("timestamp", "desc")
      .get()
      .then((snapshotA) => {
        var allRequests = [];
        snapshotA.docs.forEach((doc) => {
          const docId = doc.id;
          const docData = doc.data();
          allRequests.push({ ...docData, id: docId });
        });
        setEventRequests(allRequests)
      });
      
  }

  async function approveEventRequest(eventId, requestId, pwd){
    const approvalUrl = `https://eventsapineweventrequestapproverequest-verqhozorq-uc.a.run.app/?event_id=${eventId}&request_id=${requestId}&password=${pwd}`

  }
  async function rejectEventRequest(eventId, requestId, pwd){
    const rejectUrl =  `https://eventsapineweventrequestrejectrequest-verqhozorq-uc.a.run.app/?event_id=${eventId}&request_id=${requestId}&password=${pwd}`
  }

  const [paymentData, setPaymentData] = useState([])
  const [eventPaymentNextPage, setEventPaymentNextPage] = useState("")
  async function loadEventPaymentData(orgId){
    //
    const organizerUID = auth.currentUser.uid
    auth
    .currentUser
    .getIdToken(false)
    .then((idtoken) => {
      let dataBody = `event_id=${id}&organizer_id=${orgId}&organizer_uid=${organizerUID}`
      if (eventPaymentNextPage != ""){
        dataBody += `&page=${eventPaymentNextPage}`
      }
      fetch(
        "https://getpaymentfororganizerbyeventid-verqhozorq-uc.a.run.app?" +
          dataBody,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": `Bearer ${idtoken}`
          },
        }
      ).then((response) => {
        let jsonD = {
          success: false,
          status: 'failure',
          data: [],
          count: 0,
        }
        response.json().then((data) => {
          jsonD = data
          if (jsonD.count > 0){
            const paymentD = jsonD.data
            var paymentDat = []
            paymentD.forEach((paymentItm, order) => {
              let dateStr = ""
              if (paymentItm.created != null){
                var date = new Date(paymentItm.created * 1000);
                dateStr = date.toLocaleDateString('en') + ' ' + date.toLocaleTimeString('en')
              }
              paymentDat.push({...paymentItm, id: order+1, date: dateStr})
            })
            setPaymentData(paymentDat)
          }
          if (jsonD.next_page != null && jsonD.next_page != ""){
            setEventPaymentNextPage(jsonD.next_page)
          }
          else{
            setEventPaymentNextPage("")
          }
        })
      })
      
    })
    

  }

  async function loadEventFormQuestions() {
    await fires
      .collection("events")
      .doc(id)
      .collection("forms")
      .orderBy("order", "asc")
      .get()
      .then((snapshotA) => {
        var allQuestions = [];
        snapshotA.docs.forEach((doc) => {
          const docId = doc.id;
          const docData = doc.data();
          allQuestions.push({ ...docData, id: docId });
        });
        setEventForm(allQuestions);
      });
      if (eventForm.length > 0){
        alert(eventForm.length + " form questions have been loaded")
      }
  }

  async function loadEventInvoices() {
    fires
      .collection("events")
      .doc(id)
      .collection("invoices")
      .orderBy('timestamp','asc')
      .get()
      .then((snapshotA) => {
        var allInvs = [];
        snapshotA.docs.forEach((doc) => {
          const docId = doc.id;
          let docData = doc.data();
          let invoiceId = ""
          let priceTotal = "$0"
          let taxTotal = "$0"
          let ticketItemId = ""
          if (docData.ticketItemId != null){ ticketItemId = docData.ticketItemId}
          if (docData.invoiceNum != null){ invoiceId = docData.invoiceNum }
          if (docData.amount != null){ priceTotal = docData.amount}
          if (docData.tax != null){ taxTotal =  docData.tax}
          allInvs.push({ ...docData, 
            id: invoiceId, 
            price: priceTotal, 
            tax: taxTotal, 
            emailId: "Sent", 
            emailRef: docId});
        });
        setAllInvoiceData(allInvs)
      });
      
      
  }

  function loadEventFormSubmissions() {
    fires
      .collection("events")
      .doc(id)
      .collection("formSubmissions")
      .get()
      .then((snap) => {
        var allSubmission = [];
        snap.docs.forEach((itm) => {
          const submissionId = itm.id;
          const submissionData = itm.data();
          allSubmission.push({ ...submissionData, id: submissionId });
        });

        setAllSubmissions(allSubmission);
      });
  }

  function loadAllMembersRealTime(filterBy, orderBy) {
    //get tickets
    fires
      .collection("events")
      .doc(id)
      .collection("tickets")
      .orderBy("timestamp", "desc")
      .get()
      .then((snap) => {
        var aTickets = [];
        var bTickets = [];
        var cTickets = [];
        let ticketItmCount = {}
        snap.docs.forEach((itm, order) => {
          const ticketId = itm.id;
          let ticketData = itm.data();
          var paymentId = "";
          ticketData.id = `${order+1}. ` + ticketId
          if (ticketData.paymentId != null) {
            paymentId = ticketData.paymentId;
          }
          if (ticketData.timestamp != null){
            ticketData.date = ticketData.timestamp.toDate().toLocaleString('en')
          }
          if (paymentId == "") {
            bTickets.push(ticketData);
          } else {
            cTickets.push(ticketData);
          }
          if (ticketData.ticketType != null && ticketData.ticketType != ""){
            if (Object.keys(eventData.prices).includes(ticketData.ticketType)){
              let priceItem = eventData.prices[ticketData.ticketType]
              if (priceItem.priceDescription != null && priceItem.priceDescription != ""){
                ticketData.ticketName = priceItem.priceDescription
              }
              if (priceItem.price != null && priceItem.price > 0){
                ticketData.price = priceItem.price
              }
              //
              
               
            }
            if (Object.keys(ticketItmCount).includes(ticketData.ticketType)){
              //plus one
              ticketItmCount[ticketData.ticketType] += 1
            }
            else{
              ticketItmCount[ticketData.ticketType] = 1
            }
          }
          else{
            ticketData.ticketName = ticketData.eventTitle
          }
          aTickets.push(ticketData);
        });
        setTicketsWithPayment(cTickets);
        setTicketsWithoutPayment(bTickets);
        setAllTicketsData(aTickets);
        setTicketItemCount(ticketItmCount);
        loadEventInvoices()
        loadEventFormSubmissions();

        

      });

    

    //members
    fires
      .collection("events")
      .doc(id)
      .collection("members")
      .orderBy(filterBy, orderBy)
      .onSnapshot((snapshotA) => {
        var allMembers = [];
        var ticketMembers = [];
        snapshotA.docs.forEach((doc) => {
          const likeId = doc.id;
          const likeData = doc.data();

          var userName = "";
          var userProfileURL = "";
          var tickets = [];
          if (likeData.userName != null) {
            userName = likeData.userName;
          }
          if (likeData.userProfileURL != null) {
            userProfileURL = likeData.userProfileURL;
          }
          if (likeData.tickets != null) {
            tickets = likeData.tickets;
          }
          if (likeData.ticketId != "" && likeData.ticketId != null) {
            ticketMembers.push({
              ...likeData,
              id: likeId,
              userName: userName,
              userProfileURL: userProfileURL,
              tickets: tickets,
            });
          }
          allMembers.push({
            ...likeData,
            id: likeId,
            userName: userName,
            userProfileURL: userProfileURL,
            tickets: tickets,
          });
        });

        if (window.location.href.endsWith("#showuncheck")) {
          const uncheckMembers = allMembers.filter((memItm) => {
            return memItm.ticketId == "" || memItm.ticketId == null;
          });
          setEventDisplayMembers(uncheckMembers);
        } else if (window.location.href.endsWith("#showall")) {
          setEventDisplayMembers(allMembers);
        } else if (window.location.href.endsWith("#showchecked")) {
          const checkedMembers = allMembers.filter((memItm) => {
            return memItm.ticketId != "";
          });
          setEventDisplayMembers(checkedMembers);
        } else {
          setEventDisplayMembers(allMembers);
        }

        setEventMembers(allMembers);
        setEventTicketMembers(ticketMembers);

        var formQuestionSet = {};
        let formQuestionTitles = []
        eventForm.forEach((formItm) => {
          const formQId = formItm.id;
          const formQTitle = formItm.title;
          formQuestionTitles.push(formQTitle)
          formQuestionSet[formQId] = formQTitle;
        });

        var memberData = [];

        let memberDataKeys = [
          "name",
          "first_name",
          "last_name",
          "email",
          "check_in",
          "quantity",
          "ticket_id",
          "all_tickets",
          "register_time",
          "checkin_time",
        ];
        formQuestionTitles.forEach((qT) => {
          memberDataKeys.push(qT)
        })
        
        

        memberData.push(memberDataKeys);
        if (formQuestionTitles.length > 0){
          alert('Loaded ' + memberData[0].length + ' form questions')
        }
        

        allMembers.map((memItm) => {
          var checki = "no";
          if (memItm.checkinStatus) {
            checki = "yes";
          }
          var checkinTime = "";
          var registerTime = "";
          var forms = [];

          if (memItm.checkinTimestamp != null) {
            checkinTime = memItm.checkinTimestamp.toDate().toLocaleString("zh");
          }
          if (memItm.timestamp != null) {
            registerTime = memItm.timestamp.toDate().toLocaleString("zh");
          }
          var quantity = 1;
          if (memItm.quantity != null) {
            quantity = memItm.quantity;
          }
          var forms = [];
          if (memItm.forms != null) {
            forms = memItm.forms;
          }
          var formList = [];
          forms.forEach((formItm, findex) => {
            const formTitle = formItm.title;
            const formAnswer = formItm.value;
            formList.push(`${findex + 1}. ${formTitle}\n${formAnswer}\n`);
          });

          var memberDataValues = [
            memItm.name,
            memItm.firstName,
            memItm.lastName,
            memItm.email,
            checki,
            quantity,
            memItm.ticketId,
            memItm.tickets,
            registerTime,
            checkinTime,
          ];

          if (memItm.forms != null) {
            forms = memItm.forms;
            forms.forEach((formItm) => {
              const formQId = formItm.id;
              if (Object.keys(formQuestionSet).includes(formQId)) {
                const formQValue = formItm.value;
                memberDataValues.push(formQValue);
              }
            });
          }

          memberData.push(memberDataValues);
        });
        setCSVData(memberData);
      });
  }

  function extractUserAgent(fromUA) {
    var uaString = "";
    if (fromUA == "") {
      uaString = "无法提供";
    } else {
      if (fromUA.includes("safari")) {
        uaString = "Safari浏览器";
      } else if (fromUA.includes("micromessenger")) {
        uaString = "微信";
      } else if (fromUA.includes("instagram")) {
        uaString = "Instagram应用";
      } else if (fromUA.includes("fb")) {
        uaString = "Facebook应用";
      } else if (fromUA.includes("linkedin")) {
        uaString = "领英应用LinkedIn";
      } else if (fromUA.includes("snapchat")) {
        uaString = "Snapchat";
      } else if (fromUA.includes("twitter")) {
        uaString = "Twitter";
      } else {
        uaString = "未知";
      }
    }

    return uaString;
  }

  async function loadSuperAdminEventInformation() {
    fires
      .collection("events")
      .doc(id)
      .get()
      .then((snapshotB) => {
        if (snapshotB.exists) {
          var aData = {};
          var artiData = snapshotB.data();
          aData = artiData;
          setEventData(aData);
          const orgId = aData.orgId;
          loadEventPaymentData(orgId)

          if (artiData.options != null) {
            setEventOptions(artiData.options);
          }
          if (artiData.prices != null){
            setEventPrices(artiData.prices)
          }
          if (artiData.pin != null){
            setEventPIN(artiData.pin)
          }

          if (orgId != null) {
            if (orgId != "") {
              fires
                .collection("groups")
                .doc(orgId)
                .get()
                .then((snapshotA) => {
                  if (snapshotA.exists) {
                    const orgD = snapshotA.data();
                    setOrgData({ ...orgD });

                    //real-time
                    loadEventFormQuestions();
                    loadEventRequest()
                    loadAllMembersRealTime("name", "asc");
                  } else {
                    routeToBack();
                  }
                });
            } else {
              //event orgId empty
              routeToBack();
            }
          } else {
            routeToBack();
          }
        }
      });
  }

  async function loadRegularEventInformation(uorgId) {
    fires
      .collection("events")
      .doc(id)
      .get()
      .then((snapshotB) => {
        if (snapshotB.exists) {
          var aData = {};
          var artiData = snapshotB.data();
          aData = artiData;
          setEventData(aData);
          const orgId = aData.orgId;

          if (artiData.options != null) {
            setEventOptions(artiData.options);
          }
          if (artiData.prices != null){
            setEventPrices(artiData.prices)
          }

          loadEventPaymentData(orgId)

          if (orgId != null) {
            if (orgId != "") {
              if (uorgId == orgId) {
                fires
                  .collection("groups")
                  .doc(orgId)
                  .get()
                  .then((snapshotA) => {
                    if (snapshotA.exists) {
                      const orgD = snapshotA.data();
                      setOrgData({ ...orgD });

                      //real-time
                      loadEventFormQuestions();
                      loadEventRequest()
                      loadAllMembersRealTime("name", "asc");
                      
                    } else {
                      routeToBack();
                    }
                  });
              } else {
                //id is not match
                routeToBack();
              }
            } else {
              //event orgId empty
              routeToBack();
            }
          } else {
            routeToBack();
          }
        }
      });
  }

  useEffect(() => {
    fires
      .collection("users")
      .doc(uid)
      .get()
      .then((snapshot) => {
        if (snapshot.exists) {
          const users = [];
          const userData = snapshot.data();
          var uorgId = userData.orgId;
          if (userData.lang != null) {
            setUserLang(userData.lang);
          } else {
            setUserLang("zh");
          }
          
          fires
            .collection("admin")
            .doc(uid)
            .get()
            .then((snap_A) => {
              if (snap_A.exists) {
                //super admin
                setSuperAdminInEffect(true);
                loadSuperAdminEventInformation();
              } else {
                //check events
                setSuperAdminInEffect(false)
                //
                loadRegularEventInformation(uorgId);
                
              }
            });
        } else {
          //deleted
          posts.push({
            id: uid,
            error: "NotFound",
            error_message: "No Access Point",
            error_code: 404,
          });
          setPosts(posts);
          document.title = "EVENTGO";
          document.description = "EVENTGO";
          routeToLogout();
        }
      });
  }, []);

  const createEventModal = () => {
    return (
      <Modal
        show={showTicket}
        onHide={() => {
          setShowTicket(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>出票</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group id="invite-name" style={{ marginTop: "15px" }}>
              <Form.Label style={{ fontWeight: "bold" }}>出票人数 *</Form.Label>
              <Form.Control type="text" rows={1} required />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setShowTicket(false);
            }}
          >
            关闭
          </Button>
          <Button variant="success" onClick={(e) => {}}>
            出票
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  return (
    <>
      <FullScreen handle={fullScreenHandle}>
        <Container
          className="d-flex justify-content-center"
          style={{ minHeight: "100vh", minWidth: "100vw" }}
        >
          <div
            className="w-100"
            style={{ maxWidth: maxW, marginTop: "25px", marginBottom: "25px" }}
          >
            <Card className="border-0">
              <Card.Body className="align-items-center justify-content-center">
                {superAdminInEffect && <SuperAdminAlert superAdminInEffect={superAdminInEffect} />}
                <div className="d-flex justify-content-between align-items-start">
                  <Card.Title
                    className="text-center"
                    style={{
                      fontSize: isMobile ? "15px" : "40px",
                      fontWeight: "bold",
                      color: "#000000",
                    }}
                  >
                    {allTicketsData.length} Tickets
                  </Card.Title>
                  <div style={{ display: "flex" }}>
                    
                    
                    <Button
                      variant="dark"
                      style={{ marginRight: isMobile ? "5px" : "15px", fontSize: isMobile ? 15 : 17, fontWeight: 'normal' }}
                      onClick={() => {
                        setShowAddMembers(true);
                      }}
                    >
                      {"Add"}
                    </Button>

                    {/* <CSVLink
                      filename={"event-attendence" + ".csv"}
                      data={csvData}
                      className="btn btn-dark"
                      style={{ 
                        marginRight: isMobile ? "5px" : "15px", 
                        fontSize: isMobile ? 15 : 20, 
                        fontWeight: 'bold', 
                        color: 'white' }}
                      
                    >
                      {"Download"}
                    </CSVLink> */}

                    <DropdownButton
                      style={{ marginRight: "10px" }}
                      title={"Sort"}
                      id=""
                      variant="outline-dark"
                      onSelect={(e) => {
                        if (e == "filter-by-name") {
                          loadAllMembersRealTime("name", "asc");
                        } else if (e == "filter-by-timestamp") {
                          loadAllMembersRealTime("timestamp", "desc");
                        }
                      }}
                    >
                      <Dropdown.Item eventKey="filter-by-name">
                        {"Sort by name"}
                      </Dropdown.Item>
                      <Dropdown.Item eventKey="filter-by-timestamp">
                        {"Sort by time"}
                      </Dropdown.Item>
                    </DropdownButton>
                  </div>
                </div>

                <div>
                    <Breadcrumb>
                      <Breadcrumb.Item href="/">Events</Breadcrumb.Item>
                      <Breadcrumb.Item href={"https://tickets.evtgo.com/"+eventData.id} active>{eventData.title}</Breadcrumb.Item>
                    </Breadcrumb>
                  </div>

                
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{
                    marginTop: "15px",
                    marginBottom: "45px",
                  }}
                >
                  
                  {/* <Card.Link
                    className="text-center"
                    href={"https://tickets.evtgo.com/" + eventData.id}
                    style={{
                      fontSize: "22px",
                      fontWeight: "bold",
                      marginTop: "15px",
                      marginBottom: "0px",
                      color: "black",
                    }}
                  >
                    {eventData.title}
                  </Card.Link> */}

                  <Modal
                    show={showMemberDetails}
                    onHide={() => {
                      setShowMemberDetails(false);
                    }}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>{eventSelectedMember.name}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <Card.Title
                        className="text-center"
                        style={{ marginTop: "15px" }}
                      >
                        {userLang == "en" ? "Basic Information" : "基本信息"}
                      </Card.Title>
                      <div
                        className="align-items-start"
                        controlId="price-item"
                        style={{ marginTop: "25px" }}
                      >
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="fw-normal">
                            {userLang == "en" ? "Email" : "电子邮箱"}
                          </div>
                          <div className="fw-bold">
                            {eventSelectedMember.email}
                          </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="fw-normal">
                            {userLang == "en" ? "User Agent" : "报名来源"}
                          </div>
                          <div className="fw-bold">
                            {extractUserAgent(eventSelectedMember.fromUA)}
                          </div>
                        </div>
                        <br />
                        {eventSelectedMember.tickets.map((tickId) => {
                          return (
                            <div>
                              <Card.Link
                                style={{
                                  color: "black",
                                  fontWeight: "bold",
                                }}
                                href={
                                  "https://tickets.evtgo.com/ticket-id/" +
                                  tickId
                                }
                              >
                                {tickId}
                              </Card.Link>
                              <br />
                            </div>
                          );
                        })}
                      </div>
                      <Card.Title
                        className="text-center"
                        style={{ marginTop: "35px" }}
                        hidden={eventSelectedMember.forms.length == 0}
                      >
                        {userLang == "en"
                          ? "Answers from Questions"
                          : "问卷回答"}
                      </Card.Title>
                      <div
                        className="align-items-start"
                        controlId="price-item"
                        style={{ marginTop: "25px" }}
                      >
                        {eventSelectedMember.forms.map((formItm) => {
                          return (
                            <div className="">
                              <div className="fw-normal">{formItm.title}</div>
                              <div className="fw-bold">{formItm.value}</div>
                            </div>
                          );
                        })}
                      </div>
                      <Card.Title
                        className="text-center"
                        style={{ marginTop: "35px" }}
                        hidden={eventSelectedMember.formSubmissions.length == 0}
                      >
                        {userLang == "en" ? "More Answers" : "更多回答"}
                      </Card.Title>
                      <div
                        className="align-items-start"
                        controlId="price-item"
                        style={{ marginTop: "25px" }}
                      >
                        {eventSelectedMember.formSubmissions.map(
                          (submissionItm, index) => {
                            var submissionF = [];
                            if (submissionItm.forms != null) {
                              submissionF = submissionItm.forms;
                            }
                            return (
                              <div className="">
                                <div
                                  className="fw-bold"
                                  style={{
                                    marginBottom: "15px",
                                    marginTop: "15px",
                                  }}
                                >
                                  {index + 1}. {submissionItm.email}
                                </div>
                                {submissionF.map((formItmm) => {
                                  return (
                                    <div className="">
                                      <div className="fw-normal">
                                        {formItmm.title}
                                      </div>
                                      <div className="fw-bold">
                                        {formItmm.value}
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            );
                          }
                        )}
                      </div>

                      <Row md={3} style={{ marginTop: "60px" }}>
                        <Col>
                          <div className="d-flex align-items-center justify-content-center">
                            <Button
                              variant="primary"
                              onClick={() => {
                                const emailModifyConfirm =
                                  window.confirm(
                                    "您确定要修改该用户的邮箱吗？"
                                  );
                                if (emailModifyConfirm) {
                                  const emailInput =
                                    window.prompt("请您输入要修改的邮箱");
                                  if (
                                    emailInput != "" &&
                                    emailInput.includes("@")
                                  ) {
                                    fires
                                      .collection("events")
                                      .doc(id)
                                      .collection("members")
                                      .doc(emailInput)
                                      .set({
                                        ...eventSelectedMember,
                                        email: emailInput,
                                      });
                                    window.alert("修改成功");
                                  } else {
                                    window.alert("请输入有效的邮箱");
                                  }
                                }
                              }}
                            >
                              修改邮箱
                            </Button>
                          </div>
                        </Col>
                        <Col>
                          <div className="d-flex align-items-center justify-content-center">
                            <Button
                              variant="dark"
                              hidden={true}
                              onClick={() => {
                                
                              }}
                            >
                              催促交款
                            </Button>
                          </div>
                        </Col>

                        <Col>
                          
                        </Col>
                      </Row>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        variant="secondary"
                        onClick={() => {
                          setShowMemberDetails(false);
                        }}
                      >
                        关闭
                      </Button>
                    </Modal.Footer>
                  </Modal>

                  
                  <Modal
                    show={showAddMembers}
                    onHide={() => {
                      setShowAddMembers(false);
                    }}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Add Members</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <Card.Text></Card.Text>
                      <Form>
                        <Form.Group
                          id="member-email"
                          style={{ marginTop: "5px" }}
                        >
                          <Form.Label style={{ fontWeight: "bold" }}>
                            Email Address *
                          </Form.Label>
                          <Form.Control
                            type="email"
                            rows={1}
                            ref={eventMemberEmailRef}
                            required
                          />
                        </Form.Group>
                        <Row md={2}>
                          <Col>
                            <Form.Group
                              id="member-fn"
                              style={{ marginTop: "5px" }}
                            >
                              <Form.Label style={{ fontWeight: "bold" }}>
                                First Name *
                              </Form.Label>
                              <Form.Control
                                type="name"
                                rows={1}
                                ref={eventMemberFirstNameRef}
                                required
                              />
                            </Form.Group>
                          </Col>
                          <Col>
                            <Form.Group
                              id="member-ln"
                              style={{ marginTop: "5px" }}
                            >
                              <Form.Label style={{ fontWeight: "bold" }}>
                                Last Name *
                              </Form.Label>
                              <Form.Control
                                type="name"
                                rows={1}
                                ref={eventMemberLastNameRef}
                                required
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                        <Form.Group
                          id="member-invitee-email"
                          style={{ marginTop: "5px" }}
                        >
                          <Form.Label style={{ fontWeight: "bold" }}>
                            (Optional) Ticket Name
                          </Form.Label>
                          <Form.Control
                            type="text"
                            rows={1}
                            placeholder={"such as General Admission"}
                            ref={eventMemberInviteeEmailRef}
                          />
                          <DropdownButton 
                            style={{marginTop: '15px'}}
                            variant="outline-dark"
                            title={"Select ticket types"}
                            onSelect={(e)=>{
                              if (Object.keys(eventPrices).includes(e)){
                                let priceItm = eventPrices[e]
                                eventMemberInviteeEmailRef.current.value = priceItm.priceDescription
                                alert('selected price - ' + priceItm.priceDescription)
                              }
                              
                            }}>
                              {
                                Object.keys(eventPrices).map((priceItmId) => {
                                  let priceItm = eventPrices[priceItmId]
                                  return <Dropdown.Item eventKey={priceItmId}>{priceItm.priceDescription}</Dropdown.Item>
                                })
                              }
                          </DropdownButton>
                        </Form.Group>
                        <Form.Group
                          id="member-email"
                          style={{ marginTop: "5px" }}
                        >
                          <Form.Label style={{ fontWeight: "bold" }}>
                            Qty *
                          </Form.Label>
                          <Form.Control
                            type="number"
                            rows={1}
                            ref={eventMemberNumberRef}
                            min={1}
                            defaultValue={1}
                            required
                          />
                        </Form.Group>

                        <Form.Group
                          className="d-flex justify-content-center align-items-center"
                          style={{ marginTop: "25px" }}
                        >
                          <Button
                            variant="success"
                            onClick={() => {
                              const emailU = eventMemberEmailRef.current.value;
                              const firstNameU =
                                eventMemberFirstNameRef.current.value;
                              const lastNameU =
                                eventMemberLastNameRef.current.value;
                              const priceItemDesc =
                                eventMemberInviteeEmailRef.current.value;
                              const eventMemberNumber =
                                eventMemberNumberRef.current.value;
                              var priceDesc = "FREE";
                              var memberNumber = 1;
                              if (priceItemDesc != null) {
                                priceDesc = priceItemDesc;
                              }
                              const numMem = Number(eventMemberNumber);
                              if (numMem > 0) {
                                memberNumber = numMem;
                              }

                              const dataBody = `email=${emailU}&first_name=${firstNameU}&last_name=${lastNameU}&quantity=${memberNumber}&event_id=${id}&price_desc=${priceDesc}`;
                              fetch(
                                "https://eventsapiaddeventmembers-verqhozorq-uc.a.run.app?" +
                                  dataBody,
                                {
                                  method: "POST",
                                  headers: {
                                    "Content-Type": "application/json",
                                    "Access-Control-Allow-Origin": "*",
                                  },
                                }
                              ).then((response) => {
                                response.json().then((data) => {
                                  var dataS = { success: false, ticketId: "" };
                                  dataS = data;
                                  if (dataS.success) {
                                    window.confirm(
                                      "Email sent successfully! Ticket Number: " +
                                        dataS.ticketId
                                    );
                                    window.location.reload();
                                  } else {
                                    const sendConfirm = window.confirm("发送失败")
                                  }
                                });
                              });
                            }}
                          >
                            Invite Participant
                          </Button>
                        </Form.Group>
                      </Form>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        variant="secondary"
                        onClick={() => {
                          setShowAddMembers(false);
                        }}
                      >
                        Close
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </div>

                

                <div style={{margin: isMobile ? 0 : 60}}>
                {tabSectionComponent()}
                <div style={{marginTop: '15px'}}>
                      <div className="fw-bold">
                        {"Total"}:{" "}
                        {allTicketsData.length}<br/>
                        {"FREE Ticket"}:{" "}
                        {ticketsWithoutPayment.length}<br/>
                        {"PAID Ticket"}:
                        {ticketsWithPayment.length}
                      </div>
                    </div>
                    <div style={{marginTop: '10px'}}>
                    {
                      Object.keys(eventData.prices).map((priceItmId) => {
                        let countNum = 0
                        let maxCount = 500
                        let priceData = eventData.prices[priceItmId]
                        if (priceData.count != null){
                          maxCount = priceData.count
                        }
                        
                        if (Object.keys(ticketItemCount).includes(priceItmId)){
                          countNum = ticketItemCount[priceItmId]
                        }
                        return <div style={{marginTop: '5px'}}>
                        <div className="fw-bold">
                          {priceData.priceDescription}: {countNum} / {maxCount}
                          </div>
                          </div>
                      })
                    }
                    </div>
                    
                  {webPaymentComponent()}
                  {webMemberComponent()}

                    <ListGroup
                      style={{ marginTop: "25px" }}
                      hidden={displayMode == 1 || !isMobile}
                    >
                      {eventDisplayMembers.map((memberItm) => {
                        var langId = "zh";
                        var invitedEmail = "";
                        var quan = 1;
                        if (memberItm.lang != null) {
                          langId = memberItm.lang;
                        }
                        if (memberItm.invitedBy != null) {
                          invitedEmail = memberItm.invitedBy;
                        }
                        if (memberItm.quantity != null) {
                          quan = memberItm.quantity;
                        }
                        return (
                          <ListGroup.Item>
                            <Card.Link
                              style={{ fontSize: "15px", fontWeight: "bold" }}
                              onClick={() => {
                                const restSubmissions = allSubmission.filter(
                                  (submissionItm) => {
                                    var submissionEmail = "";
                                    if (submissionItm.email != null) {
                                      submissionEmail = submissionItm.email;
                                    }
                                    return memberItm.email == submissionEmail;
                                  }
                                );

                                var fromUAString = "";
                                var eventMemberForms = [];
                                if (memberItm.forms != null) {
                                  eventMemberForms = memberItm.forms;
                                }
                                if (memberItm.fromUA != null) {
                                  fromUAString = memberItm.fromUA;
                                }
                                setEventSelectedMember({
                                  ...memberItm,
                                  name: memberItm.name,
                                  email: memberItm.email,
                                  forms: eventMemberForms,
                                  fromUA: fromUAString,
                                  quantity: quan,
                                  formSubmissions: restSubmissions,
                                });
                                setShowMemberDetails(true);
                              }}
                            >
                              {memberItm.name}
                            </Card.Link>
                            <Card.Text
                              style={{ fontSize: "15px", marginTop: "5px" }}
                            >
                              {memberItm.email}
                            </Card.Text>
                            <Card.Text
                              style={{ fontSize: "15px", marginTop: "5px" }}
                            >
                              <b>{quan}</b> Tickets
                            </Card.Text>
                          </ListGroup.Item>
                        );
                      })}
                    </ListGroup>

                    <ListGroup
                      style={{ marginTop: "25px" }}
                      hidden={displayMode == 1 || !isMobile}
                    >
                      {allTicketsData.map((ticketItm) => {
                        var ticketId = ""
                        var ticketPaymentId = "";
                        var ticketItmId = "";
                        var ticketCustomerId = "";
                        var ticketName = ""
                        var ticketUserName = "";
                        var ticketTimestamp =
                          firebase.firestore.Timestamp.fromDate(new Date());

                        if (ticketItm.ticketId != null) {
                          ticketId = ticketItm.ticketId;
                        }
                        if (ticketItm.paymentId != null) {
                          ticketPaymentId = ticketItm.paymentId;
                        }
                        if (ticketItm.customerUID != null) {
                          ticketCustomerId = ticketItm.customerUID;
                        }
                        if (ticketItm.timestamp != null) {
                          ticketTimestamp = ticketItm.timestamp;
                        }
                        if (ticketItm.userName != null) {
                          ticketUserName = ticketItm.userName;
                        }
                        if (ticketItm.ticketType != null){
                          ticketItmId = ticketItm.ticketType
                          if (eventData.prices != null){
                            if (Object.keys(eventData.prices).includes(ticketItmId)){
                              let priceItm = eventData.prices[ticketItmId]
                              if (priceItm.priceDescription != null){
                                ticketName = priceItm.priceDescription
                              }
                            }
                          }
                        }

                        return (
                          <ListGroup.Item>
                            <Card.Link
                              style={{ fontSize: "15px", fontWeight: "bold" }}
                              href={
                                "https://tickets.evtgo.com/ticket-id/" +
                                ticketId
                              }
                            >
                              {ticketId}
                            </Card.Link>
                            <Card.Text
                              style={{
                                fontSize: "15px",
                                marginTop: "5px",
                                fontWeight: "bold",
                              }}
                            >
                              {ticketUserName}
                            </Card.Text>
                            <Card.Text
                              style={{
                                fontSize: "15px",
                                marginTop: "5px",
                                fontWeight: "bold",
                              }}
                            >
                              {ticketPaymentId == "" ? "FREE" : "PAID"}
                            </Card.Text>
                            <Card.Text
                              style={{
                                fontSize: "15px",
                                marginTop: "5px",
                                fontWeight: "bold",
                              }}
                            >
                              {ticketName == "" ? ticketItmId : ticketName}
                            </Card.Text>
                            <Card.Text
                              style={{ fontSize: "15px", marginTop: "10px" }}
                            >
                              {ticketTimestamp.toDate().toLocaleString("zh")}
                            </Card.Text>
                          </ListGroup.Item>
                        );
                      })}
                    </ListGroup>

                    

                    

                    <div style={{marginTop: 25, marginBottom: 0}}>
                      <Card.Text style={{fontWeight: 'bold', fontSize: 17, marginBottom: 5}}>{allTicketsData.length} Tickets</Card.Text>
                    </div>
                    { webTicketComponent() }
                    { webInvoiceComponent() }
                    { webRequestComponent() }
                    

                    <ListGroup hidden={displayMode == 0}>
                      <Row md={3}>
                        {eventDisplayMembers.map((memberItm) => {
                          var memberTickets = [];
                          var memberQuantity = 1;
                          if (memberItm.tickets != null) {
                            memberTickets = memberItm.tickets;
                          }
                          if (memberItm.quantity != null) {
                            memberQuantity = memberItm.quantity;
                          }

                          return (
                            <Col>
                              <ListGroup.Item style={{ marginBottom: "25px" }}>
                                <div>
                                  <div
                                    className="align-items-start"
                                    style={{
                                      alignSelf: "center",
                                      display: "flex",
                                    }}
                                  >
                                    <Image
                                      roundedCircle
                                      src={memberItm.profileURL}
                                      width="50px"
                                      height="50px"
                                    />
                                    <div style={{ marginLeft: "5px" }}>
                                      <Card.Text
                                        style={{
                                          fontSize: "18px",
                                          fontWeight: "bold",
                                          marginBottom: "0px",
                                          color: "black",
                                        }}
                                      >
                                        {memberItm.name}
                                      </Card.Text>
                                      <Card.Text
                                        class="text-secondary"
                                        style={{
                                          fontSize: "12px",
                                          fontWeight: "normal",
                                        }}
                                      >
                                        {memberItm.timestamp
                                          .toDate()
                                          .toLocaleString("zh")}
                                      </Card.Text>
                                    </div>
                                  </div>
                                  <div className="d-flex justify-content-between align-items-start">
                                    <Card.Text style={{}}>
                                      电子邮箱: {memberItm.email}
                                    </Card.Text>
                                  </div>
                                  <div className="d-flex justify-content-between align-items-start">
                                    <Card.Text style={{}}>
                                      出票ID: {memberItm.ticketId}
                                    </Card.Text>
                                  </div>
                                  <div className="d-flex justify-content-between align-items-start">
                                    <Card.Text style={{}}>
                                      总人数: {memberQuantity}
                                    </Card.Text>
                                  </div>
                                  <div className="d-flex justify-content-between align-items-start">
                                    <Card.Text style={{}}>
                                      待出票人数: {memberTickets.length}
                                    </Card.Text>
                                  </div>
                                  <div
                                    className="d-flex justify-content-between align-items-start"
                                    style={{ marginTop: "5px" }}
                                  >
                                    <Card.Text
                                      style={{
                                        color: eventInviteeEmails.includes(
                                          memberItm.email
                                        )
                                          ? "green"
                                          : "red",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {eventInviteeEmails.includes(
                                        memberItm.email
                                      )
                                        ? "已邀请"
                                        : "未邀请"}
                                    </Card.Text>
                                  </div>

                                  <Accordion
                                    defaultActiveKey="A"
                                    style={{
                                      marginTop: "15px",
                                      marginBottom: "15px",
                                    }}
                                  >
                                    {memberTickets.map((ticketItm) => {
                                      return (
                                        <Accordion.Item key={ticketItm}>
                                          <Accordion.Header>
                                            {ticketItm}
                                          </Accordion.Header>
                                          <Accordion.Body>
                                            <div>
                                              <Card.Link
                                                onClick={() => {
                                                  var timestampNow =
                                                    firebase.firestore.Timestamp.fromDate(
                                                      new Date()
                                                    );

                                                  var newEmailId =
                                                    memberItm.email +
                                                    "_" +
                                                    ticketItm;

                                                  var memberSet = {
                                                    email: memberItm.email,
                                                    firstName:
                                                      memberItm.firstName,
                                                    lastName:
                                                      memberItm.lastName,
                                                    name: memberItm.name,
                                                    checkinStatus: true,
                                                    checkinTimestamp:
                                                      timestampNow,
                                                    handsup: false,
                                                    thumbup: false,
                                                    thumbdown: false,
                                                    handsclap: false,
                                                    handsup: false,
                                                    ticketId: ticketItm,
                                                    invitedBy: memberItm.email,
                                                    emailId: newEmailId,
                                                    wechat: "",
                                                    work: "",
                                                    timestamp: timestampNow,
                                                  };

                                                  let confirmA = window.confirm(
                                                    "确定添加 " + newEmailId
                                                  );
                                                  let confirmName =
                                                    window.prompt(
                                                      "输入您的名字？"
                                                    );
                                                  if (confirmA) {
                                                    if (confirmName != "") {
                                                      memberSet.name =
                                                        confirmName;
                                                    }
                                                    fires
                                                      .collection("events")
                                                      .doc(id)
                                                      .collection("members")
                                                      .doc(newEmailId)
                                                      .set(memberSet)
                                                      .then(() => {
                                                        let cIndex =
                                                          memberTickets.indexOf(
                                                            ticketItm
                                                          );
                                                        if (
                                                          cIndex >= 0 &&
                                                          cIndex <
                                                            memberTickets.length
                                                        ) {
                                                          memberTickets.splice(
                                                            cIndex,
                                                            1
                                                          );
                                                          fires
                                                            .collection(
                                                              "events"
                                                            )
                                                            .doc(id)
                                                            .collection(
                                                              "members"
                                                            )
                                                            .doc(
                                                              memberItm.email
                                                            )
                                                            .update({
                                                              tickets:
                                                                memberTickets,
                                                            });
                                                        }
                                                      });
                                                  }
                                                }}
                                              >
                                                签到
                                              </Card.Link>
                                            </div>
                                          </Accordion.Body>
                                        </Accordion.Item>
                                      );
                                    })}
                                  </Accordion>

                                  <div
                                    className="d-flex justify-content-between align-items-start"
                                    style={{ marginTop: "5px" }}
                                  >
                                    <Card.Text
                                      style={{
                                        color: memberItm.checkinStatus
                                          ? "green"
                                          : "black",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {memberItm.checkinStatus
                                        ? "已出票"
                                        : "未出票"}
                                    </Card.Text>
                                    <div>
                                      <Card.Link onClick={() => {}}>
                                        {!memberItm.checkinStatus
                                          ? "出票"
                                          : "取消签到"}
                                      </Card.Link>
                                      <Card.Link>联系</Card.Link>
                                      <Card.Link
                                        style={{ color: "red" }}
                                        onClick={() => {
                                          if (memberItm.email != "") {
                                            const deleteConfirm =
                                              window.confirm(
                                                "名字: " +
                                                  memberItm.name +
                                                  "\n电子邮箱: " +
                                                  memberItm.email +
                                                  "\n警告⚠️ 您确定要删除吗？删除后不可恢复"
                                              );
                                            if (deleteConfirm) {
                                              var emailId = "";
                                              if (memberItm.emailId != null) {
                                                emailId = memberItm.emailId;
                                                fires
                                                  .collection("events")
                                                  .doc(id)
                                                  .collection("members")
                                                  .doc(emailId)
                                                  .delete()
                                                  .then(() => {});
                                              } else {
                                                fires
                                                  .collection("events")
                                                  .doc(id)
                                                  .collection("members")
                                                  .doc(memberItm.email)
                                                  .delete()
                                                  .then(() => {});
                                              }
                                            }
                                          }
                                        }}
                                      >
                                        删除
                                      </Card.Link>
                                    </div>
                                  </div>
                                </div>
                              </ListGroup.Item>
                            </Col>
                          );
                        })}
                      </Row>
                    </ListGroup>

                </div>
              </Card.Body>
            </Card>
          </div>
        </Container>
      </FullScreen>
    </>
  );
}
