import React, { useRef, useState, useEffect } from "react";
import {
  Form,
  Button,
  Card,
  Container,
  ListGroup,
  Accordion,
  Dropdown,
  DropdownButton,
  Row,
  Col,
  Image,
  Nav,
  NavDropdown,
  Carousel,
  Navbar,
  Modal,
  FormGroup,
} from "react-bootstrap";
import { useAuth } from "../contexts/AuthContext";
import { fires } from "../firebase";
import { useNavigate, useParams } from "react-router-dom";
import { isMobile } from "react-device-detect";
import firebase from "firebase";
import "firebase/firestore";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { QRCodeCanvas } from "qrcode.react";
import Iframe from "react-iframe";
import { LuckyWheel } from "@lucky-canvas/react";
import { FaCheckCircle } from "react-icons/fa";

export default function LiveEventDashboard() {
  const { currentUser, logout } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [posts, setPosts] = useState([]);
  const [viewTypes, setViewTypes] = useState([]);
  const [views, setViews] = useState([]);
  const [todayViews, setTodayViews] = useState([]);

  const [eventData, setEventData] = useState({});
  const [eventComments, setEventComments] = useState([]);
  const [eventPhotos, setEventPhotos] = useState([]);
  const [eventSponsors, setEventSponsors] = useState([]);
  const [eventLikes, setEventLikes] = useState([]);
  const [eventMembers, setEventMembers] = useState([]);
  const [eventCheckinMembers, setEventCheckinMembers] = useState([]);
  const [eventHandRaiseMembers, setEventHandRaiseMembers] = useState([]);
  const [eventThumbUpMembers, setEventThumbUpMembers] = useState([]);
  const [eventThumbDownMembers, setEventThumbDownMembers] = useState([]);
  const [eventHandsClapMembers, setEventHandsClapMembers] = useState([]);

  //draw and winner
  const [eventDrawMembers, setEventDrawMembers] = useState([]);
  const [eventWinnerMembers, setEventWinnderMembers] = useState([]);
  const [eventLastCheckinMember, setEventLastCheckinMember] = useState({
    name: "",
    ticketId: "",
    email: "",
    emailId: "",
    firstName: "",
    lastName: "",
  });

  const [eventLuckyDrawMember, setEventLuckyDrawMember] = useState({
    name: "",
    ticketId: "",
    email: "",
    emailId: "",
  });
  const [drawItems, setDrawItems] = useState({});
  const [currentDrawItem, setCurrentDrawItem] = useState({
    name: "",
    description: "",
    url: "",
    imageURL: "",
    order: 0,
    total: 0,
    remaining: 0,
    id: "",
  });
  const [drawId, setDrawId] = useState("");
  var ddId = "";

  const [eventPics, setEventPics] = useState([]);

  //playing type
  const [eventPlayType, setEventPlayType] = useState(0);

  const pptFullScreen = useFullScreenHandle();
  const userFullScreen = useFullScreenHandle();
  const fullScreenHandle = useFullScreenHandle();
  const [enteredFullScreen, setEnteredFullScreen] = useState(false);

  const myLucky = useRef();
  const [blocks] = useState([{ padding: "10px", background: "#869cfa" }]);
  const [prizes, setPrizes] = useState([
    { background: "#e9e8fe", fonts: [{ text: "0" }] },
    { background: "#b8c5f2", fonts: [{ text: "1" }] },
    { background: "#e9e8fe", fonts: [{ text: "2" }] },
    { background: "#b8c5f2", fonts: [{ text: "3" }] },
    { background: "#e9e8fe", fonts: [{ text: "4" }] },
    { background: "#b8c5f2", fonts: [{ text: "5" }] },
  ]);
  const [buttons] = useState([
    { radius: "40%", background: "#617df2" },
    { radius: "35%", background: "#afc8ff" },
    {
      radius: "30%",
      background: "#869cfa",
      pointer: true,
      fonts: [{ text: "开始", top: "-10px" }],
    },
  ]);

  function drawShuffle(array) {
    let currentIndex = array.length,
      randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex != 0) {
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }

    return array;
  }

  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  //draw
  const [showDrawModal, setShowDrawModal] = useState(false);
  const handleDrawCloseModal = () => setShowDrawModal(false);
  const handleDrawShowModal = () => setShowDrawModal(true);

  const [showWinnerModal, setShowWinnerModal] = useState(false);
  const handleWinnerCloseModal = () => setShowWinnerModal(false);
  const handleWinnerShowModal = () => setShowWinnerModal(true);

  //presenting
  const presentingLinkRef = useRef();

  //poll
  const [poll, setPoll] = useState({
    title: "",
    description: "",
    type: "options",
    options: 0,
  });

  //options

  const [eventOptions, setEventOptions] = useState({
    poll: false,
    draw: false,
    namecard: false,
    review: false,
    comment: true,
    picture: true,
    "register-feedback": false,
    max: 500,
  });

  const maxW = isMobile ? "100%" : "100%";

  var uid = currentUser.uid;
  var cEmail = currentUser.email;
  var timestampNow = firebase.firestore.Timestamp.fromDate(new Date());

  let { id } = useParams();

  const routeToLogout = () => {
    logout();
    let path = "/";
    window.location.href = path;
  };

  const isToday = (someDate) => {
    const today = new Date();
    return (
      someDate.getDate() == today.getDate() &&
      someDate.getMonth() == today.getMonth() &&
      someDate.getFullYear() == today.getFullYear()
    );
  };

  useEffect(() => {
    fires
      .collection("users")
      .doc(uid)
      .get()
      .then((snapshot) => {
        if (snapshot.exists) {
          const users = [];
          const userData = snapshot.data();
          var orgId = userData.orgId;
          fires
            .collection("events")
            .doc(id)
            .onSnapshot((snapshotA) => {
              if (snapshotA.exists) {
                var aData = {};
                var artiData = snapshotA.data();
                aData = artiData;
                if (orgId == artiData.orgId) {
                  setEventData(aData);
                  if (aData.poll != null) {
                    setPoll(aData.poll);
                  }
                  if (aData.drawItems != null) {
                    const allDraws = aData.drawItems;
                    setDrawItems(allDraws);
                    if (ddId != "") {
                      setCurrentDrawItem(allDraws[ddId]);
                    }
                  }
                  if (aData.options != null) {
                    setEventOptions(aData.options);
                  }
                  if (aData.photoURLs != null) {
                    setEventPhotos(aData.photoURLs);
                  }
                  if (aData.sponsor != null) {
                    setEventSponsors(aData.sponsor);
                  }
                } else {
                  routeToLogout();
                }
              } else {
                routeToLogout();
              }
            });
          fires
            .collection("events")
            .doc(id)
            .get()
            .then((snapshotB) => {
              if (snapshotB.exists) {
                var aData = {};
                var artiData = snapshotB.data();
                aData = artiData;
                setEventData(aData);
                if (orgId == artiData.orgId) {
                  //eventData

                  //messages
                  fires
                    .collection("events")
                    .doc(id)
                    .collection("messages")
                    .onSnapshot((snapshotA) => {
                      var allComments = [];
                      snapshotA.docs.forEach((doc) => {
                        const commentId = doc.id;
                        const commentData = doc.data();

                        var userName = "";
                        var profileURL = "";
                        if (commentData.fromName != null) {
                          userName = commentData.fromName;
                        }
                        if (commentData.profileURL != null) {
                          profileURL = commentData.profileURL;
                        }

                        allComments.push({
                          ...commentData,
                          id: commentId,
                          fromName: userName,
                          profileURL: profileURL,
                        });
                      });
                      setEventComments(allComments);
                    });

                  //members
                  fires
                    .collection("events")
                    .doc(id)
                    .collection("members")
                    .onSnapshot((snapshotA) => {
                      var allMembers = [];
                      var checkinMembers = [];
                      var handsUpMembers = [];
                      var thumbUpMembers = [];
                      var thumbDownMembers = [];
                      var handsClapMembers = [];

                      var drawMembers = [];
                      var drawWinnerMembers = [];

                      snapshotA.docs.forEach((doc) => {
                        const memberId = doc.id;
                        const memberData = doc.data();

                        var userName = "";
                        var userProfileURL = "";

                        if (memberData.userName != null) {
                          userName = memberData.userName;
                        }
                        if (memberData.userProfileURL != null) {
                          userProfileURL = memberData.userProfileURL;
                        }
                        if (memberData.handsup != null) {
                          if (memberData.handsup) {
                            handsUpMembers.push({
                              ...memberData,
                              id: memberId,
                            });
                          }
                        }
                        if (memberData.thumbup != null) {
                          if (memberData.thumbup) {
                            thumbUpMembers.push({
                              ...memberData,
                              id: memberId,
                            });
                          }
                        }
                        if (memberData.thumbdown != null) {
                          if (memberData.thumbdown) {
                            thumbDownMembers.push({
                              ...memberData,
                              id: memberId,
                            });
                          }
                        }
                        if (memberData.handsclap != null) {
                          if (memberData.handsclap) {
                            handsClapMembers.push({
                              ...memberData,
                              id: memberId,
                            });
                          }
                        }
                        if (memberData.checkinStatus != null) {
                          if (memberData.checkinStatus) {
                            checkinMembers.push({
                              ...memberData,
                              id: memberId,
                            });
                            //getting draws
                            if (memberData.ticketId != null) {
                              if (memberData.ticketId != "") {
                                if (memberData.drawOptions != null) {
                                  if (memberData.drawOptions != "") {
                                    drawWinnerMembers.push({
                                      ...memberData,
                                      id: memberId,
                                      email: memberData.email,
                                      userName: userName,
                                      userProfileURL: userProfileURL,
                                    });
                                  } else {
                                    drawMembers.push({
                                      ...memberData,
                                      id: memberId,
                                      email: memberData.email,
                                      userName: userName,
                                      userProfileURL: userProfileURL,
                                    });
                                  }
                                } else {
                                  drawMembers.push({
                                    ...memberData,
                                    id: memberId,
                                    email: memberData.email,
                                    userName: userName,
                                    userProfileURL: userProfileURL,
                                  });
                                }
                              }
                            }
                          }
                        }

                        allMembers.push({
                          ...memberData,
                          id: memberId,
                          email: memberData.email,
                          userName: userName,
                          userProfileURL: userProfileURL,
                        });
                      });
                      setEventMembers(allMembers);
                      setEventCheckinMembers(checkinMembers);
                      setEventHandRaiseMembers(handsUpMembers);
                      setEventThumbUpMembers(thumbUpMembers);
                      setEventThumbDownMembers(thumbDownMembers);
                      setEventHandsClapMembers(handsClapMembers);

                      setEventDrawMembers(drawMembers);
                      setEventWinnderMembers(drawWinnerMembers);

                      const sortedCheckinMembers = checkinMembers.sort(
                        (memA, memB) => {
                          var aTimestamp =
                            firebase.firestore.Timestamp.fromDate(
                              new Date(1998, 11, 11)
                            );
                          var bTimestamp =
                            firebase.firestore.Timestamp.fromDate(
                              new Date(1998, 11, 11)
                            );
                          if (memA.checkinTimestamp != null) {
                            aTimestamp = memA.checkinTimestamp;
                          }
                          if (memB.checkinTimestamp != null) {
                            bTimestamp = memB.checkinTimestamp;
                          }
                          const aDate = aTimestamp.toDate();
                          const bDate = bTimestamp.toDate();
                          return bDate - aDate;
                        }
                      );

                      var newEmails = "";
                      if (sortedCheckinMembers.length > 0) {
                        sortedCheckinMembers.map((sortedItm) => {
                          newEmails += "\n" + sortedItm.email;
                        });
                        const latestMem = sortedCheckinMembers[0];
                        setEventLastCheckinMember({ ...latestMem });
                      }

                      var priz = [];
                      var prizC = 0;
                      drawMembers.map((memItm) => {
                        var cBackground = "#e9e8fe";
                        prizC += 1;
                        if (prizC % 2 == 0) {
                          cBackground = "#b8c5f2";
                        }
                        var textName = "";
                        if (drawMembers.length <= 50) {
                          textName = memItm.name;
                        }
                        var emailId = "";
                        if (memItm.emailId != null) {
                          emailId = memItm.emailId;
                        } else {
                          emailId = memItm.email;
                        }
                        var langMem = "zh";
                        if (memItm.lang != null) {
                          if (memItm.lang == "en") {
                            langMem = "en";
                          } else if (memItm.lang == "zh") {
                            langMem = "zh";
                          }
                        } else {
                          langMem = "zh";
                        }
                        let drawItm = {
                          background: cBackground,
                          fonts: [
                            {
                              ticketId: memItm.ticketId,
                              id: memItm.id,
                              emailId: emailId,
                              lang: langMem,
                              text: textName,
                              email: memItm.email,
                              name: memItm.name,
                            },
                          ],
                        };
                        priz.push(drawItm);
                      });
                      setPrizes(drawShuffle(priz));
                    });
                } else {
                  //quit
                  //deleted
                  navigate(-1);
                }
              }
            });
        } else {
          //deleted
          posts.push({
            id: uid,
            error: "NotFound",
            error_message: "没有访问权限",
            error_code: 404,
          });
          setPosts(posts);
          document.title = "Event Go";
          document.description = "Event Go Lucky Draw System";
          routeToLogout();
        }
      });
  }, []);

  function handlePresentationFromClipboard(e) {
    navigator.clipboard.readText().then((text) => {
      const pasteURL = text;
      if (pasteURL != "") {
        validateYoutubeEmbed(pasteURL);
      }
    });
  }

  function handleShowCurrentVideo(e) {
    setEventPlayType(0);
  }

  function handleShowSponsor(e) {
    setEventPlayType(3);
  }

  function handleShowDrawWinner(e) {
    setEventPlayType(4);
  }

  function handleShowLastCheckIn(e) {
    setEventPlayType(5);
  }

  function handleShowMyPicture(e) {
    setEventPlayType(1);
  }

  function handleShowUGPicture(e) {
    setEventPlayType(2);
  }

  function validateYoutubeEmbed(linkStr) {
    var finalEmbedURL = "";
    if (linkStr.includes("youtu")) {
      if (linkStr.includes("v=")) {
        const linkPId = linkStr.split("v=")[1];
        if (linkPId.includes("&")) {
          const linkId = linkPId.split("&")[0];
          finalEmbedURL = "https://youtube.com/embed/" + linkId;
        } else {
          const linkId = linkPId;
          finalEmbedURL = "https://youtube.com/embed/" + linkId;
        }
      } else if (linkStr.includes("youtu.be/")) {
        const linkPId = linkStr.split("youtu.be/")[1];
        if (linkPId.includes("&")) {
          const linkId = linkPId.split("&")[0];
          finalEmbedURL = "https://youtube.com/embed/" + linkId;
        } else {
          const linkId = linkPId;
          finalEmbedURL = "https://youtube.com/embed/" + linkId;
        }
      } else {
        finalEmbedURL = linkStr;
      }
    } else {
      finalEmbedURL = linkStr;
    }
    fires
      .collection("events")
      .doc(id)
      .update({
        presentingURL: finalEmbedURL,
        presentingTempURL: finalEmbedURL,
        presentingTimestamp: timestampNow,
      })
      .then(() => {
        handleCloseModal();
        //window.location.reload()
      });
  }

  function actionClearAllReactions(e) {
    eventMembers.map((memItm) => {
      if (memItm.email != "") {
        fires
          .collection("events")
          .doc(id)
          .collection("members")
          .doc(memItm.email)
          .update({
            handsup: false,
            thumbup: false,
            thumbdown: false,
            handsclap: false,
          })
          .then(() => {});
      }
    });
  }

  function actionClearAllHandRaise(e) {
    eventMembers.map((memItm) => {
      if (memItm.email != "") {
        fires
          .collection("events")
          .doc(id)
          .collection("members")
          .doc(memItm.email)
          .update({ handsup: false })
          .then(() => {});
      }
    });
  }

  function sendDrawWinnerEmailTo(email, ticketId, name, prizeName, lang) {
    var emailTitle = "";
    var htmlString = "";

    if (lang == "zh") {
      var eventOrg = "";
      if (eventData.user.userOrgNames != null) {
        if (eventData.user.userOrgNames.zh != null) {
          eventOrg = eventData.user.userOrgNames.zh;
        } else {
          eventOrg = eventData.user.userOrg;
        }
      } else {
        eventOrg = eventData.user.userOrg;
      }

      var eventName = "";
      if (eventData.titles != null) {
        if (eventData.titles.zh != null) {
          eventName = eventData.titles.zh;
        } else {
          eventName = eventData.title;
        }
      } else {
        eventName = eventData.title;
      }

      emailTitle = "恭喜您在活动<" + eventName + ">中中奖";
      const htmlSubject =
        "<h2>恭喜您在活动「<b>" + eventName + "</b>」中中奖</h2>";
      const body01 = "<p>您好 " + name + ",</p><br>";
      const body02 =
        "<p>收到这封信意味着您在<b>" +
        eventName +
        "</b>中获得奖品，请您拿着这封电子邮件或门票号码「" +
        ticketId +
        "」前往台前领取。</p>";
      const body03 = "<p>以下是奖品的详细信息</p>";
      const body04 = "<p>奖品名称: <b>" + prizeName + "</b></p>";
      const body05 = "<p>获奖人电子邮箱: <b>" + email + "</b></p>";
      const body06 = "<p>获奖人门票: <b>" + ticketId + "</b></p>";
      const body07 =
        "<h4><b>请点击<a href='https://tickets.evtgo.com/" +
        eventData.id +
        "'>活动链接</a>查看详情</h4><br>";
      const body08 =
        "<p>发现更多活动，欢迎访问<a href='https://evtgo.com'>Event Go</a></p>";
      const body09 = "<br><h3><b>" + eventOrg + "</b></h3>";
      htmlString =
        htmlSubject +
        body01 +
        body02 +
        body03 +
        body04 +
        body05 +
        body06 +
        body07 +
        body08 +
        body09;
    } else if (lang == "en") {
      var eventOrg = "";
      if (eventData.user.userOrgNames != null) {
        if (eventData.user.userOrgNames.en != null) {
          eventOrg = eventData.user.userOrgNames.en;
        } else {
          eventOrg = eventData.user.userOrg;
        }
      } else {
        eventOrg = eventData.user.userOrg;
      }

      var eventName = "";
      if (eventData.titles != null) {
        if (eventData.titles.en != null) {
          eventName = eventData.titles.en;
        } else {
          eventName = eventData.title;
        }
      } else {
        eventName = eventData.title;
      }

      emailTitle = "Congratulations! You won the prize in <" + eventName + ">";
      const htmlSubject =
        "<h2>Congratulations! You are the winner in event <b>" +
        eventName +
        "</b></h2>";
      const body01 = "<p>Hello " + name + ",</p><br>";
      const body02 =
        "<p>Receiving this email indicated you have won the prize in event <b>" +
        eventName +
        "</b>, Please redeem your prize with the ticket ID「" +
        ticketId +
        "」</p>";
      const body03 = "<p>The following is the information of the prize</p>";
      const body04 = "<p>Name of prize: <b>" + prizeName + "</b></p>";
      const body05 = "<p>Email of winner: <b>" + email + "</b></p>";
      const body06 = "<p>Ticket ID: <b>" + ticketId + "</b></p>";
      const body07 =
        "<h4><b>Please click <a href='https://tickets.evtgo.com/" +
        eventData.id +
        "/en'>this link</a> for details of the event</h4><br>";
      const body08 =
        "<p>Discover more events, please visit <a href='https://tickets.evtgo.com'>Event Go Website</a></p>";
      const body09 = "<br><h3><b>" + eventOrg + "</b></h3>";
      htmlString =
        htmlSubject +
        body01 +
        body02 +
        body03 +
        body04 +
        body05 +
        body06 +
        body07 +
        body08 +
        body09;
    } else {
      var eventOrg = "";
      if (eventData.user.userOrgNames != null) {
        if (eventData.user.userOrgNames.zh != null) {
          eventOrg = eventData.user.userOrgNames.zh;
        } else {
          eventOrg = eventData.user.userOrg;
        }
      } else {
        eventOrg = eventData.user.userOrg;
      }

      var eventName = "";
      if (eventData.titles != null) {
        if (eventData.titles.zh != null) {
          eventName = eventData.titles.zh;
        } else {
          eventName = eventData.title;
        }
      } else {
        eventName = eventData.title;
      }
      emailTitle = "恭喜您在活动<" + eventName + ">中中奖";
      const htmlSubject =
        "<h2>恭喜您在活动「<b>" + eventName + "</b>」中中奖</h2>";
      const body01 = "<p>您好 " + name + ",</p><br>";
      const body02 =
        "<p>收到这封信意味着您在<b>" +
        eventName +
        "</b>中获得奖品，请您拿着这封电子邮件或门票号码「" +
        ticketId +
        "」前往台前领取。</p>";
      const body03 = "<p>以下是奖品的详细信息</p>";
      const body04 = "<p>奖品名称: <b>" + prizeName + "</b></p>";
      const body05 = "<p>获奖人电子邮箱: <b>" + email + "</b></p>";
      const body06 = "<p>获奖人门票: <b>" + ticketId + "</b></p>";
      const body07 =
        "<h4><b>请点击<a href='https://tickets.evtgo.com/" +
        eventData.id +
        "'>活动链接</a>查看详情</h4><br>";
      const body08 =
        "<p>发现更多活动，欢迎访问<a href='https://evtgo.com'>Event Go</a></p>";
      const body09 = "<br><h3><b>" + eventOrg + "</b></h3>";
      htmlString =
        htmlSubject +
        body01 +
        body02 +
        body03 +
        body04 +
        body05 +
        body06 +
        body07 +
        body08 +
        body09;
    }

    const messageObj = {
      subject: emailTitle,
      html: htmlString,
    };
    fires
      .collection("mail")
      .add({
        to: [email],
        message: messageObj,
      })
      .then((docRef) => {
        const sentId = docRef.id;
      });
  }

  function actionOpenFeedback(e) {
    setPoll({
      title: "问卷时间",
      description: "请选择下列问题",
      options: 4,
      type: "options",
    });
    const newPoll = {
      title: "问卷时间",
      description: "请选择下列问题",
      options: 4,
      type: "options",
    };
    fires.collection("events").doc(id).update({ poll: newPoll });
  }

  function actionCloseFeedback(e) {
    fires.collection("events").doc(id).update({ poll: {} });
  }

  function actionAddComment(e) {}

  function actionOpenDraws() {}

  function actionDeleteAllComment(e) {
    const deleteA = window.confirm("您想要删除所有评论吗?");
    if (deleteA) {
      fires
        .collection("events")
        .doc(id)
        .collection("messages")
        .get()
        .then((docRef) => {
          docRef.docs.forEach((docD) => {
            const messageId = docD.id;
            fires
              .collection("events")
              .doc(id)
              .collection("messages")
              .doc(messageId)
              .delete();
          });
        });
    }
  }

  return (
    <>
      <FullScreen handle={fullScreenHandle}>
        <Navbar bg="dark" variant="dark" expand="lg" sticky="top">
          <Container fluid>
            <Navbar.Brand href="/"><div className="fw-bold">Event Go活动平台</div></Navbar.Brand>
            <Navbar.Toggle aria-controls="navbarScroll" />
            <Navbar.Collapse id="navbarScroll">
              <Nav className="me-auto my-2 my-lg-0" navbarScroll>
                <NavDropdown title="大屏幕选项" id="basic-nav-dropdown">
                  <NavDropdown.Item onClick={handleShowCurrentVideo}>
                    播放演示链接
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={handleShowMyPicture}>
                    播放我设置的图片
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={handleShowUGPicture}>
                    播放用户上传的图片
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={handleShowSponsor}>
                    播放赞助商介绍
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={handleShowDrawWinner}>
                    播放抽奖界面
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={handleShowLastCheckIn}>
                    播放签到界面
                  </NavDropdown.Item>
                </NavDropdown>
                <NavDropdown title="演示链接" id="basic-nav-dropdown">
                  <NavDropdown.Item onClick={handleShowModal}>
                    输入演示链接
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={handlePresentationFromClipboard}>
                    粘贴演示链接
                  </NavDropdown.Item>
                </NavDropdown>
                <NavDropdown title="反馈" id="basic-nav-dropdown">
                  <NavDropdown.Item
                    onClick={() => {
                      var eventtOptions = eventOptions;
                      eventtOptions.comment = !eventtOptions.comment;
                      fires
                        .collection("events")
                        .doc(id)
                        .update({ options: eventtOptions });
                    }}
                  >
                    {eventOptions.comment ? "关闭留言" : "打开留言"}
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    onClick={() => {
                      var eventtOptions = eventOptions;
                      eventtOptions.picture = !eventtOptions.picture;
                      fires
                        .collection("events")
                        .doc(id)
                        .update({ options: eventtOptions });
                    }}
                  >
                    {eventOptions.picture ? "关闭图片上传" : "打开图片上传"}
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    onClick={() => {
                      var eventtOptions = eventOptions;
                      eventtOptions.poll = !eventtOptions.poll;
                      fires
                        .collection("events")
                        .doc(id)
                        .update({ options: eventtOptions });
                    }}
                  >
                    {eventOptions.poll ? "关闭问卷" : "打开问卷"}
                  </NavDropdown.Item>
                </NavDropdown>
                <NavDropdown title="评论" id="basic-nav-dropdown">
                  <NavDropdown.Item onClick={actionAddComment}>
                    添加留言
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={actionDeleteAllComment}>
                    删除所有留言
                  </NavDropdown.Item>
                </NavDropdown>
                <NavDropdown title="清理" id="basic-nav-dropdown">
                  <NavDropdown.Item onClick={actionClearAllReactions}>
                    清理全部提示
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={actionClearAllHandRaise}>
                    清理举手
                  </NavDropdown.Item>
                </NavDropdown>
                <NavDropdown title="抽奖" id="basic-nav-dropdown">
                  {Object.keys(drawItems).map((drawKey) => {
                    const drawSet = drawItems[drawKey];
                    return (
                      <NavDropdown.Item
                        onClick={() => {
                          if (drawSet.remaining > 0) {
                            setCurrentDrawItem({ ...drawSet, id: drawKey });
                            handleDrawShowModal();
                          }
                        }}
                      >
                        {drawSet.name} - {drawSet.remaining.toString()}
                      </NavDropdown.Item>
                    );
                  })}
                </NavDropdown>
                <NavDropdown title="全屏" id="basic-nav-dropdown">
                  <NavDropdown.Item>演示全屏</NavDropdown.Item>
                  <NavDropdown.Item>提问全屏</NavDropdown.Item>
                  <NavDropdown.Item>活动日程全屏</NavDropdown.Item>
                </NavDropdown>
                <NavDropdown title="语言" id="basic-nav-dropdown">
                  <NavDropdown.Item>简体中文</NavDropdown.Item>
                  <NavDropdown.Item>繁体中文</NavDropdown.Item>
                  <NavDropdown.Item>ENGLISH</NavDropdown.Item>
                </NavDropdown>
              </Nav>
            </Navbar.Collapse>
            <Button
              variant="dark"
              onClick={() => {
                if (enteredFullScreen) {
                  setEnteredFullScreen(false);
                  fullScreenHandle.exit();
                } else {
                  setEnteredFullScreen(true);
                  fullScreenHandle.enter();
                }
              }}
            >
              {enteredFullScreen ? "取消全屏" : "全屏"}
            </Button>
          </Container>
        </Navbar>
        <Container
          className="d-flex justify-content-center"
          style={{ maxHeight: "100vh", maxWidth: "100vw" }}
        >
          <div className="w-100" style={{}}>
            <Modal show={showModal} onHide={handleCloseModal}>
              <Modal.Header closeButton>
                <Modal.Title>演示开始</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <Form.Group id="presenting-link" style={{}}>
                    <Form.Label style={{ fontWeight: "bold" }}>
                      请输入演示链接 *
                    </Form.Label>
                    <Form.Control
                      type="text"
                      rows={1}
                      ref={presentingLinkRef}
                      required
                    />
                  </Form.Group>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseModal}>
                  关闭
                </Button>
                <Button
                  variant="success"
                  onClick={(e) => {
                    const presentingL = presentingLinkRef.current.value;
                    if (presentingL != "") {
                      handleCloseModal();
                      validateYoutubeEmbed(presentingL);
                    }
                  }}
                >
                  确认并开始
                </Button>
              </Modal.Footer>
            </Modal>

            <Modal show={showWinnerModal} onHide={handleWinnerCloseModal}>
              <Modal.Header closeButton>
                <Modal.Title>中奖啦</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <Form.Group>
                    <Form.Label
                      style={{ fontWeight: "bold" }}
                      hidden={eventLuckyDrawMember.email == ""}
                    >
                      恭喜中奖
                    </Form.Label>
                    <br />
                    <br />
                    <Form.Label
                      style={{ fontWeight: "bold" }}
                      hidden={eventLuckyDrawMember.email == ""}
                    >
                      名字: {eventLuckyDrawMember.name}
                    </Form.Label>
                    <br />
                    <br />
                    <Form.Label
                      style={{ fontWeight: "bold" }}
                      hidden={eventLuckyDrawMember.email == ""}
                    >
                      电子邮箱: {eventLuckyDrawMember.email}
                    </Form.Label>
                    <br />
                    <br />
                    <Form.Label
                      style={{ fontWeight: "bold" }}
                      hidden={eventLuckyDrawMember.email == ""}
                    >
                      收据号: {eventLuckyDrawMember.ticketId}
                    </Form.Label>
                    <br />
                    <br />
                    <Form.Label
                      style={{ fontWeight: "bold" }}
                      hidden={eventLuckyDrawMember.email == ""}
                    >
                      请您来领奖！
                    </Form.Label>
                  </Form.Group>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => {
                    setEventLuckyDrawMember({
                      name: "",
                      ticketId: "",
                      email: "",
                      emailId: "",
                    });
                    handleWinnerCloseModal();
                  }}
                >
                  关闭
                </Button>
              </Modal.Footer>
            </Modal>

            <Modal show={showDrawModal} onHide={handleDrawCloseModal}>
              <Modal.Header closeButton>
                <Modal.Title>幸运大抽奖</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <FormGroup className="d-flex justify-content-between align-items-start">
                    <Form.Label
                      className="text-center"
                      style={{
                        fontSize: "20px",
                        fontWeight: "bold",
                        color: "#000000",
                        marginBottom: "45px",
                      }}
                    >
                      可抽奖的人数: {eventDrawMembers.length}
                    </Form.Label>
                  </FormGroup>
                  <FormGroup className="align-items-start">
                    <Card.Img
                      hidden={currentDrawItem.imageURL == ""}
                      src={currentDrawItem.imageURL}
                      width="150px"
                      height="150px"
                    />
                    <div
                      className="d-flex align-items-center justify-content-center"
                      style={{ marginTop: "10px" }}
                    >
                      <Form.Label
                        className="text-center"
                        style={{
                          fontSize: "15px",
                          fontWeight: "bold",
                          color: "#000000",
                          marginBottom: "15px",
                        }}
                      >
                        {currentDrawItem.name +
                          "(" +
                          currentDrawItem.remaining +
                          ")"}
                      </Form.Label>
                    </div>
                  </FormGroup>

                  <div className="d-flex align-items-center justify-content-center">
                    <LuckyWheel
                      ref={myLucky}
                      width="400px"
                      height="400px"
                      blocks={blocks}
                      prizes={prizes}
                      buttons={buttons}
                      onStart={() => {
                        // 点击抽奖按钮会触发star回调
                        if (currentDrawItem.id != "") {
                          if (currentDrawItem.remaining > 0) {
                            myLucky.current.play();
                            setTimeout(() => {
                              const index = (Math.random() * 6) >> 0;
                              myLucky.current.stop(index);
                            }, 2500);
                          }
                        }
                      }}
                      onEnd={(prize) => {
                        const dEmail = prize.fonts[0].email;
                        const dTicketId = prize.fonts[0].ticketId;
                        const dName = prize.fonts[0].name;
                        const dEmailId = prize.fonts[0].emailId;
                        const dLang = prize.fonts[0].lang;
                        const currentDDID = currentDrawItem.id;
                        const currentPrizeName = currentDrawItem.name;

                        let confirmPrize = window.confirm(
                          "请您确定中奖的人:\n姓名: " +
                            dName +
                            "\n门票号: " +
                            dTicketId +
                            "\n邮箱: " +
                            dEmail
                        );
                        if (confirmPrize) {
                          if (currentDDID != "") {
                            fires
                              .collection("events")
                              .doc(id)
                              .collection("members")
                              .doc(dEmailId)
                              .update({ drawOptions: currentDDID })
                              .then(() => {});
                            var drawwItems = drawItems;
                            var cDrawItem = currentDrawItem;
                            if (cDrawItem.remaining > 0) {
                              cDrawItem.remaining = cDrawItem.remaining - 1;
                              drawwItems[currentDDID] = cDrawItem;
                              fires
                                .collection("events")
                                .doc(id)
                                .update({ drawItems: drawwItems })
                                .then(() => {
                                  setEventLuckyDrawMember({
                                    name: dName,
                                    ticketId: dTicketId,
                                    email: dEmail,
                                    emailId: dEmailId,
                                  });
                                  sendDrawWinnerEmailTo(
                                    dEmail,
                                    dTicketId,
                                    dName,
                                    currentPrizeName,
                                    dLang
                                  );
                                  handleWinnerShowModal();
                                });
                            }
                          }
                        } else {
                          fires
                            .collection("events")
                            .doc(id)
                            .collection("members")
                            .doc(dEmailId)
                            .update({ drawOptions: "no-show" })
                            .then(() => {});
                        }
                      }}
                    />
                  </div>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => {
                    setPrizes(drawShuffle(prizes));
                  }}
                >
                  乱序
                </Button>
                <Button variant="secondary" onClick={handleDrawCloseModal}>
                  关闭
                </Button>
              </Modal.Footer>
            </Modal>

            <Card className="border-0">
              <Card.Body className="align-items-center justify-content-center">
                <Row>
                  <Col sm={9} style={{ height: "90vh" }}>
                    <div
                      hidden={eventPlayType != 0}
                      style={{
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <Iframe
                        url={eventData.presentingURL}
                        width="100%"
                        height="95%"
                        id="iframe-present"
                      />
                    </div>

                    <Carousel
                      hidden={eventPlayType != 1}
                      style={{ width: "100%", height: "100%" }}
                    >
                      {eventPhotos.map((phoURL) => {
                        return (
                          <Carousel.Item interval={5000}>
                            <Image
                              style={{
                                width: "100%",
                                maxHeight: "650px",
                              }}
                              src={phoURL}
                              alt="photo"
                            />
                          </Carousel.Item>
                        );
                      })}
                    </Carousel>

                    <Carousel
                      hidden={eventPlayType != 2}
                      style={{ width: "100%", height: "100%" }}
                    >
                      {eventPics.map((phoItm) => {
                        return (
                          <Carousel.Item interval={2500}>
                            <img
                              style={{
                                width: "100%",
                                height: "100%",
                              }}
                              src={phoItm.imageURL}
                              alt="photo"
                            />
                            <Carousel.Caption>
                              <h4>来自{phoItm.name}</h4>
                            </Carousel.Caption>
                          </Carousel.Item>
                        );
                      })}
                    </Carousel>

                    <Carousel
                      hidden={eventPlayType != 3}
                      style={{ width: "100%", height: "100%" }}
                    >
                      {eventSponsors.map((sponsorItm) => {
                        return (
                          <Carousel.Item
                            interval={5000}
                            style={{
                              alignSelf: "center",
                              alignContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Image
                              style={{
                                width: "100%",
                                maxHeight: "650px",
                                alignSelf: "center",
                                alignContent: "center",
                                alignItems: "center",
                              }}
                              src={sponsorItm.imageURL}
                              alt="sponsor"
                              className="img-fluid shadow-4 center-block"
                            />
                          </Carousel.Item>
                        );
                      })}
                    </Carousel>

                    <div
                      hidden={eventPlayType != 4}
                      style={{
                        width: "100%",
                        height: "95%",
                      }}
                    >
                      <Card
                        className="d-flex align-items-center justify-content-center border-0"
                        style={{
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        <Form>
                          <FormGroup className="d-flex justify-content-between align-items-start">
                            <Form.Label
                              style={{
                                fontSize: "20px",
                                fontWeight: "bold",
                                color: "#000000",
                                marginBottom: "45px",
                              }}
                            >
                              可抽奖的人数: {eventDrawMembers.length}
                            </Form.Label>
                            <div>
                              <DropdownButton
                                title={"抽奖"}
                                id="dropdown-menu-align-right"
                                variant={"outline-dark"}
                                onSelect={(e) => {
                                  if (e == "close") {
                                  } else if (e == "shuffle") {
                                    setPrizes(drawShuffle(prizes));
                                  } else {
                                    if (Object.keys(drawItems).includes(e)) {
                                      const drawSet = drawItems[e];
                                      if (drawSet.remaining > 0) {
                                        setCurrentDrawItem({
                                          ...drawSet,
                                          id: e,
                                        });
                                        //handleDrawShowModal()
                                      }
                                    }
                                  }
                                }}
                              >
                                {Object.keys(drawItems).map((drawKey) => {
                                  const drawSet = drawItems[drawKey];
                                  return (
                                    <Dropdown.Item
                                      eventKey={drawKey}
                                      style={{ fontWeight: "bold" }}
                                    >
                                      {drawSet.name} -{" "}
                                      {drawSet.remaining.toString()}
                                    </Dropdown.Item>
                                  );
                                })}
                                <Dropdown.Divider />
                                <Dropdown.Item
                                  eventKey="shuffle"
                                  style={{ color: "black", fontWeight: "bold" }}
                                >
                                  乱序
                                </Dropdown.Item>
                                <Dropdown.Item
                                  eventKey="close"
                                  style={{ color: "red", fontWeight: "bold" }}
                                >
                                  关闭
                                </Dropdown.Item>
                              </DropdownButton>
                            </div>
                          </FormGroup>
                          <FormGroup className="align-items-start">
                            <Card.Img
                              hidden={currentDrawItem.imageURL == ""}
                              src={currentDrawItem.imageURL}
                              width="150px"
                              height="150px"
                            />
                            <div
                              className="d-flex align-items-center justify-content-center"
                              style={{ marginTop: "10px" }}
                            >
                              <Form.Label
                                className="text-center"
                                style={{
                                  fontSize: "15px",
                                  fontWeight: "bold",
                                  color: "#000000",
                                  marginBottom: "15px",
                                }}
                              >
                                {currentDrawItem.name +
                                  "(" +
                                  currentDrawItem.remaining +
                                  ")"}
                              </Form.Label>
                            </div>
                          </FormGroup>

                          <div className="d-flex align-items-center justify-content-center">
                            <LuckyWheel
                              ref={myLucky}
                              width="500px"
                              height="500px"
                              blocks={blocks}
                              prizes={prizes}
                              buttons={buttons}
                              onStart={() => {
                                // 点击抽奖按钮会触发star回调
                                if (currentDrawItem.id != "") {
                                  if (currentDrawItem.remaining > 0) {
                                    myLucky.current.play();
                                    setTimeout(() => {
                                      const index = (Math.random() * 6) >> 0;
                                      myLucky.current.stop(index);
                                    }, 2500);
                                  }
                                }
                              }}
                              onEnd={(prize) => {
                                const dEmail = prize.fonts[0].email;
                                const dTicketId = prize.fonts[0].ticketId;
                                const dName = prize.fonts[0].name;
                                const dEmailId = prize.fonts[0].emailId;
                                const dLang = prize.fonts[0].lang;
                                const currentDDID = currentDrawItem.id;
                                const currentPrizeName = currentDrawItem.name;

                                let confirmPrize = window.confirm(
                                  "请您确定中奖的人:\n姓名: " +
                                    dName +
                                    "\n门票号: " +
                                    dTicketId +
                                    "\n邮箱: " +
                                    dEmail
                                );
                                if (confirmPrize) {
                                  if (currentDDID != "") {
                                    fires
                                      .collection("events")
                                      .doc(id)
                                      .collection("members")
                                      .doc(dEmailId)
                                      .update({ drawOptions: currentDDID })
                                      .then(() => {});
                                    var drawwItems = drawItems;
                                    var cDrawItem = currentDrawItem;
                                    if (cDrawItem.remaining > 0) {
                                      cDrawItem.remaining =
                                        cDrawItem.remaining - 1;
                                      drawwItems[currentDDID] = cDrawItem;
                                      fires
                                        .collection("events")
                                        .doc(id)
                                        .update({ drawItems: drawwItems })
                                        .then(() => {
                                          setEventLuckyDrawMember({
                                            name: dName,
                                            ticketId: dTicketId,
                                            email: dEmail,
                                            emailId: dEmailId,
                                          });
                                          sendDrawWinnerEmailTo(
                                            dEmail,
                                            dTicketId,
                                            dName,
                                            currentPrizeName,
                                            dLang
                                          );
                                          handleWinnerShowModal();
                                        });
                                    }
                                  }
                                } else {
                                  fires
                                    .collection("events")
                                    .doc(id)
                                    .collection("members")
                                    .doc(dEmailId)
                                    .update({ drawOptions: "no-show" })
                                    .then(() => {});
                                }
                              }}
                            />
                          </div>
                        </Form>

                        {/* <Card.Title className="text-center" style={{fontSize: '75px', fontWeight: 'bold', color: 'black'}}>{eventLuckyDrawMember.name}</Card.Title>
                  <Card.Text className="text-center" style={{fontSize: '50px', fontWeight: 'bold', color: 'black'}}>{eventLuckyDrawMember.ticketId}</Card.Text> */}
                      </Card>
                    </div>

                    <div
                      hidden={eventPlayType != 5}
                      style={{
                        width: "100%",
                        height: "95%",
                      }}
                    >
                      <Card
                        className="d-flex align-items-center justify-content-center border-0"
                        style={{
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        <FaCheckCircle size="25px" />
                        <Card.Text
                          className="text-center"
                          style={{
                            fontSize: "25px",
                            fontWeight: "bold",
                            color: "black",
                          }}
                        >
                          欢迎您
                        </Card.Text>
                        <Card.Title
                          className="text-center"
                          style={{
                            fontSize: "75px",
                            fontWeight: "bold",
                            color: "black",
                          }}
                        >
                          {eventLastCheckinMember.name}
                        </Card.Title>
                        <Card.Text
                          className="text-center"
                          style={{
                            fontSize: "50px",
                            fontWeight: "bold",
                            color: "black",
                          }}
                        >
                          {eventLastCheckinMember.ticketId}
                        </Card.Text>
                      </Card>
                    </div>
                  </Col>

                  <Col sm={3} style={{ overflowY: "scroll", height: "90vh" }}>
                    <ListGroup.Item className="d-flex align-items-start">
                      <div
                        className="qrcode__container"
                        style={{ marginRight: "15px" }}
                      >
                        <QRCodeCanvas
                          id="qrCode"
                          value={
                            "https://tickets.evtgo.com/" +
                            eventData.id
                          }
                          size={150}
                          bgColor={"#ffffff"}
                          fgColor={"#000000"}
                          level={"L"}
                          includeMargin={false}
                          hidden={eventData.status == "private"}
                        />
                      </div>
                      <div>
                        
                        <Card.Text
                          style={{
                            fontSize: "18px",
                            fontWeight: "bold",
                            color: "black",
                          }}
                        >
                          {eventData.title}
                        </Card.Text>
                        <Card.Link onClick={()=>{
                          let windowConfirm = window.confirm("您确定需要全部签到？全部签到所有人将获得抽奖机会")
                          if (windowConfirm){
                            eventMembers.forEach((memberItm) => {
                              let email = memberItm.email
                              if (email != null && email != ""){
                                fires
                                                  .collection("events")
                                                  .doc(id)
                                                  .collection("members")
                                                  .doc(email)
                                                  .update({
                                                    checkinStatus: true,
                                                    checkinTimestamp:
                                                      timestampNow,
                                                    checkinBy: "admin",
                                                  })
                                                  .then(() => {
  
                                                  });
  
                              }
                            })
                          }
                        }}>全部签到</Card.Link>
                        <Card.Text
                          style={{ fontSize: "12px", fontWeight: "normal" }}
                        >
                          实时人数({eventCheckinMembers.length})
                        </Card.Text>
                      </div>
                    </ListGroup.Item>

                    <Accordion
                      id="all-control"
                      defaultActiveKey="A"
                      style={{ marginTop: "15px" }}
                    >
                      <Accordion.Item eventKey="A">
                        <Accordion.Header
                          className="d-flex justify-content-between align-items-start"
                          style={{ backgroundColor: "#EEE" }}
                        >
                          <div className="fw-bold">{"活动已签到"}</div>
                          <div className="fw-bold">
                            ({eventCheckinMembers.length})
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          <ListGroup>
                            {eventCheckinMembers.map((memberItm) => {
                              return (
                                <ListGroup.Item>
                                  <div className="d-flex justify-content-between align-items-start">
                                    <div>
                                      <Card.Text
                                        style={{
                                          fontSize: "15px",
                                          fontWeight: "normal",
                                          marginBottom: "0px",
                                          color: "black",
                                        }}
                                      >
                                        {memberItm.name}
                                      </Card.Text>
                                    </div>

                                    <div>
                                      <Card.Link
                                        style={{
                                          color: "black",
                                          textDecoration: "none",
                                        }}
                                      >
                                        {memberItm.thumbup ? "👍" : ""}
                                      </Card.Link>
                                      <Card.Link
                                        style={{
                                          color: "black",
                                          textDecoration: "none",
                                        }}
                                      >
                                        {memberItm.handsclap ? "👏" : ""}
                                      </Card.Link>
                                      <Card.Link
                                        style={{
                                          color: "black",
                                          textDecoration: "none",
                                        }}
                                      >
                                        {memberItm.handsup ? "🙋" : ""}
                                      </Card.Link>
                                    </div>
                                  </div>
                                </ListGroup.Item>
                              );
                            })}
                          </ListGroup>
                        </Accordion.Body>
                      </Accordion.Item>

                      <Accordion.Item eventKey="B">
                        <Accordion.Header
                          className="d-flex justify-content-between align-items-start"
                          style={{ backgroundColor: "#EEE" }}
                        >
                          <div className="fw-bold">{"活动已参与人数"}</div>
                          <div className="fw-bold">({eventMembers.length})</div>
                        </Accordion.Header>
                        <Accordion.Body>
                          <ListGroup style={{ marginTop: "15px" }}>
                            {eventMembers.map((memberItm) => {
                              return (
                                <ListGroup.Item>
                                  <div
                                    className="align-items-start"
                                    style={{
                                      alignSelf: "center",
                                      display: "flex",
                                    }}
                                  >
                                    <Image
                                      roundedCircle
                                      src={memberItm.profileURL}
                                      width="50px"
                                      height="50px"
                                    />
                                    <div style={{ marginLeft: "5px" }}>
                                      <Card.Text
                                        style={{
                                          fontSize: "18px",
                                          fontWeight: "bold",
                                          marginBottom: "0px",
                                          color: "black",
                                        }}
                                      >
                                        {memberItm.name}
                                      </Card.Text>
                                      <Card.Text
                                        class="text-secondary"
                                        style={{
                                          fontSize: "12px",
                                          fontWeight: "normal",
                                        }}
                                      >
                                        {memberItm.timestamp
                                          .toDate()
                                          .toLocaleString("zh")}
                                      </Card.Text>
                                    </div>
                                  </div>
                                  <div className="d-flex justify-content-between align-items-start">
                                    <Card.Text
                                      style={{
                                        color: memberItm.checkinStatus
                                          ? "green"
                                          : "black",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {memberItm.checkinStatus
                                        ? "已签到"
                                        : "未签到"}
                                    </Card.Text>
                                    <div>
                                      <Card.Link
                                        onClick={() => {
                                          const checkinAlert = window.confirm(
                                            "你想要帮" +
                                              memberItm.name +
                                              "签到吗?"
                                          );
                                          if (checkinAlert) {
                                            var timestampNow =
                                              firebase.firestore.Timestamp.fromDate(
                                                new Date()
                                              );
                                            if (
                                              memberItm.email != "" &&
                                              !memberItm.checkinStatus
                                            ) {
                                              fires
                                                .collection("events")
                                                .doc(id)
                                                .collection("members")
                                                .doc(memberItm.email)
                                                .update({
                                                  checkinStatus: true,
                                                  checkinTimestamp:
                                                    timestampNow,
                                                  checkinBy: "admin",
                                                })
                                                .then(() => {});
                                            }
                                          }
                                        }}
                                      >
                                        替TA签到
                                      </Card.Link>
                                      <Card.Link
                                        style={{ color: "red" }}
                                        onClick={() => {
                                          const deleteAlert = window.confirm(
                                            "你想要取消" +
                                              memberItm.name +
                                              "的签到吗?"
                                          );
                                          if (deleteAlert) {
                                            if (memberItm.email != "") {
                                              fires
                                                .collection("events")
                                                .doc(id)
                                                .collection("members")
                                                .doc(memberItm.email)
                                                .update({
                                                  checkinStatus: false,
                                                  checkinBy: "admin",
                                                })
                                                .then(() => {});
                                            }
                                          }
                                        }}
                                      >
                                        取消签到
                                      </Card.Link>
                                    </div>
                                  </div>
                                </ListGroup.Item>
                              );
                            })}
                          </ListGroup>
                        </Accordion.Body>
                      </Accordion.Item>

                      <Accordion.Item eventKey="C">
                        <Accordion.Header
                          className="d-flex justify-content-between align-items-start"
                          style={{ backgroundColor: "#EEE" }}
                        >
                          <div className="fw-bold">{"活动留言 "}</div>
                          <div className="fw-bold">
                            ({eventComments.length})
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          <ListGroup style={{ marginTop: "15px" }}>
                            {eventComments.map((commentItm) => {
                              return (
                                <ListGroup.Item>
                                  <div
                                    className="align-items-start"
                                    style={{
                                      alignSelf: "center",
                                      display: "flex",
                                    }}
                                  >
                                    <Image
                                      roundedCircle
                                      src={commentItm.profileURL}
                                      width="50px"
                                      height="50px"
                                    />
                                    <div style={{ marginLeft: "5px" }}>
                                      <Card.Text
                                        style={{
                                          fontSize: "18px",
                                          fontWeight: "bold",
                                          marginBottom: "0px",
                                          color: "black",
                                        }}
                                      >
                                        {commentItm.name}
                                      </Card.Text>
                                      <Card.Text
                                        class="text-secondary"
                                        style={{
                                          fontSize: "12px",
                                          fontWeight: "normal",
                                        }}
                                      >
                                        {"评论于 " +
                                          commentItm.timestamp
                                            .toDate()
                                            .toLocaleString("zh")}
                                      </Card.Text>
                                    </div>
                                  </div>
                                  <div className="d-flex justify-content-between align-items-start">
                                    <div className="fw-bold">
                                      {commentItm.message}
                                    </div>
                                  </div>

                                  <div style={{ marginTop: "15px" }}>
                                    <Card.Link
                                      style={{
                                        color: "black",
                                        fontWeight: "bold",
                                      }}
                                      onClick={() => {}}
                                    >
                                      公共评论
                                    </Card.Link>
                                    <Card.Link
                                      style={{
                                        color: "black",
                                        fontWeight: "bold",
                                      }}
                                      onClick={() => {}}
                                    >
                                      {"精选"}
                                    </Card.Link>
                                  </div>
                                </ListGroup.Item>
                              );
                            })}
                          </ListGroup>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </div>
        </Container>
      </FullScreen>
    </>
  );
}
